import { path, pathOr } from "ramda";

const INVALID_TOKEN_NAME = "InvalidJwtToken";
const INVALID_TOKEN_MESSAGE = "Your session has expired. Please login again.";

const getResErrMessage = (error) => {
  const errors = path(["response", "data", "errors"], error);
  const oneErr = path(["response", "data", "error", "message"], error);
  const errCSV = path(["response", "data", "message"], error);
  const errVideo = path(["response", "message"], error);
  const errName = path(["response", "data", "error", "name"], error);
  let message = "Unknown error";

  if (errors) {
    message = "";
    Object.keys(errors).forEach((key) => {
      message += `${key.replace("_", " ")}: ${pathOr(
        errors[key],
        [key, "message"],
        errors
      )} </br>`;
    });
  } else if (errName === INVALID_TOKEN_NAME && typeof errName === "string") {
    message = INVALID_TOKEN_MESSAGE;
  } else if (oneErr && typeof oneErr === "string") {
    message = oneErr;
  } else if (errCSV && typeof errCSV === "string") {
    message = errCSV;
  } else if (errVideo && typeof errVideo === "string") {
    message = errVideo;
  } 
  return message;
};

export { getResErrMessage };
