import { handleActions } from "redux-actions";
import types from "./types";
import { mergeIn } from "../../utils/helpers/stateHelpers";

const initialState = {
  token: null,
  user: null,
  members: [],

};

const reducer = handleActions(
  {
    [types.SET_MEMBER_PASSWORD_SUCCESS]: mergeIn(
      ({ payload: { data } }) => ({
        token: data.token,
        user: data.user,
      })
    ),
    [types.GET_MEMBERS_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { data},
        },
      }) => ({ members: data })
    ),
    [types.CREATE_MEMBER_SUCCESS]: mergeIn(
      ({
        payload: {
          members
        },
      }) => ({
        members,
      })
    ),
    [types.UPDATE_MEMBER_SUCCESS]: mergeIn(
      ({ payload: { 
          members
        } 
      }) => ({
        members,
      })
    ),
    
  },
  initialState
);

export default reducer;
