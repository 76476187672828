import { handleActions } from "redux-actions";
import types from "./types";
import { mergeIn } from "../../utils/helpers/stateHelpers";
import { userTypes } from "../user";

const initialState = {
  error: null,
  voiceTrainingCompletedSteps: 0,
  requestLoading: false,
  menus: {
    main: null,
    profile: null,
  },
  countdown: 0,
  authErrors: null,
  redirectTo: null,
};

const reducer = handleActions(
  {
    [types.GET_MENU_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { main, profile },
        },
      }) => ({
        menus: {
          main: main.children,
          profile: profile.children,
        },
      })
    ),
    [types.REQUEST_LOADING]: mergeIn(({ payload }) => ({
      requestLoading: payload,
    })),
    [types.SET_ERROR]: mergeIn(({ payload }) => {
      return { error: payload };
    }),
    [types.COUNTDOWN_CHANGE]: mergeIn(({ payload }) => ({
      countdown: payload,
    })),
    [userTypes.LOGIN_REQUEST_FAIL]: mergeIn(({ payload: { data } }) => ({
      authErrors: data?.errors,
    })),
    [types.REDIRECT_TO]: mergeIn(({ payload }) => ({
      redirectTo: payload,
    })),
  },
  initialState
);

export default reducer;
