import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS_VALUE,
} from "../../../constants/common";
import CampaignListItem from "./campaignListItem";
import { CampaignsModel } from "../../../utils/helpers/defPropTypes";
import { campaignsOperation } from "../../../store/campaigns";
import CustomSelectSort from '../customSelectSort'
import "./style.scss";

const CampaignsList = ({ campaigns }) => {
  const {draft, active, inactive} = campaigns
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getCampaigns = (params) => dispatch(campaignsOperation.getCampaignsRequest({...params}));

  const sortValues = [
    {value: "asc", title: "A to Z"},
    {value: "desc", title: "Z to A"},
    {value: "created_at", title: "Date created"},
    {value: "id", title: "Original order"}
  ]
  const arrList = [
    {item: draft, type: false, name: "draft"},
    {item: active, type: true, name: "active"},
    {item: inactive, type: true, name: "inactive"}
  ]

  const deleteHandler = (campaign) => {
    dispatch(campaignsOperation.deleteCampaignRequest(campaign.id));
  };

  const duplicateHandler = (campaign) => {
    dispatch(campaignsOperation.duplicateCampaignRequest(campaign.id));
  };

  const changeStatusHandler = (campaign, checked) => {
    dispatch(
      campaignsOperation.updateCampaignRequest({
        id: campaign.id,
        step: CAMPAIGN_STEPS_VALUE.PUBLISH_COMPLETE,
        index: campaign.index,
        csv_file_id: campaign.csv_file_id,
        status: checked
          ? CAMPAIGN_STATUSES.active.id
          : CAMPAIGN_STATUSES.inactive.id,
      })
    );
  };

  const sortHandler = (type, val) => {
    let params = {}
    if(val === "asc" || val=== "desc"){
      params = {order: val, sortBy: "campaign_title"}
    } else {
      params = {sortBy: val}
    }
     getCampaigns({campaignsStatus: type, params: { status: CAMPAIGN_STATUSES[type].id,  ...params}})
  }

  return (
    <div className="CampaignsList">
      {arrList.map(({item, type, name}) => {
        return item && item.length > 0 && (
          <figure className="CampaignsList_category" key={`${name}-key-figure`}>
            <div className="CampaignsList_category__wrap">
              <figcaption className="CampaignsList_category__title">
                {t(CAMPAIGN_STATUSES[name].name)}
              </figcaption>
              {type && <CustomSelectSort
                key={`select-${name} `}
                defaultValue={t("Sort")}
                onChange={(e) => sortHandler(name, e.target.value)}
                values={sortValues}
              />
              }
            </div>
            <ul className="CampaignsList_category__list m-b-25">
              {item.map((campaign) => (
                <CampaignListItem
                  key={`campaignItem-${campaign.id}`}
                  onDelete={deleteHandler}
                  onChangeStatus={type && changeStatusHandler}
                  campaign={campaign}
                  onDuplicate={duplicateHandler}

                />
              ))}
            </ul>
          </figure>
        )
      })}
    </div>
  );
};

CampaignsList.propTypes = {
  campaigns: CampaignsModel.isRequired,
};

export default CampaignsList;
