import types from "./types";
import apiUrls from "../../constants/apiUrls";
import { logout } from "./action";
import Storage from "../../utils/helpers/Storage";

const login = (data) => {
  return {
    type: types.LOGIN_REQUEST,
    payload: {
      request: { url: apiUrls.login, method: "POST", data },
    },
  };
};

const signOut = () => (dispatch) => {
  Storage.remove(process.env.REACT_APP_TOKEN_KEY);
  dispatch(logout());
};

const registerRequest = (data) => ({
  type: types.REGISTER_REQUEST,
  payload: {
    request: { url: apiUrls.register, method: "POST", data },
  },
});

const updateFirstAuthUserRequest = (user) => ({
  type: types.USER_UPDATE_FIRST_AUTH,
  payload: {
    request: {
      url: apiUrls.updateUserProfile,
      method: "PUT",
      data: user,
    },
  },
});

const getCurrentUserRequest = () => ({
  type: types.GET_CURRENT_USER,
  payload: {
    request: { url: apiUrls.currentUser, method: "GET" },
  },
});

const forgotPasswordRequest = (data) => ({
  type: types.FORGOT_PASSWORD,
  payload: {
    request: { url: apiUrls.forgotPassword, method: "POST", data },
  },
});

const recoveryPasswordRequest = (data) => ({
  type: types.RECOVERY_PASSWORD,
  payload: {
    request: { url: apiUrls.recoveryPassword, method: "POST", data },
  },
});

const updateProfileRequest = (data) => ({
  type: types.UPDATE_PROFILE,
  payload: {
    request: { url: apiUrls.updateUserProfile, method: "PUT", data },
  },
});

export {
  login,
  signOut,
  registerRequest,
  updateFirstAuthUserRequest,
  getCurrentUserRequest,
  forgotPasswordRequest,
  recoveryPasswordRequest,
  updateProfileRequest,
};
