import React, { useCallback } from "react";
import { Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AuthFooter, AuthHeader, Input } from "../../../components";
import { AuthForm } from "../../../containers";
// import { ROUTES } from "../../../constants/routes";
import { setMemberPasswordRequest } from "../../../store/team-members/operation";
import { newMemberPasswordValidator } from "./newMemberPasswordValidator";

const NewMemberPassword = ({ location: { search } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const id = new URLSearchParams(search).get("id");
  const code = new URLSearchParams(search).get("code");

  const loading = useSelector(({ ui }) => ui.requestLoading);

  const onSubmitHandler = useCallback(
    (values) => {
      dispatch(setMemberPasswordRequest({ ...values, code, id }));
    },
    [id, code]
  );

  return (
    <div className="Auth">
      <AuthHeader isButton={false} />

      <div className="Auth_content new_member">
        <AuthForm
          title={t(`Welcome to parruu. Please set your password`)}
          onSubmit={onSubmitHandler}
          initialValues={{ password: "" }}
          validate={newMemberPasswordValidator}
        >
          {({ errors }) => (
            <Form>
              <Input
                className="m-b-20"
                type="password"
                name="password"
                error={errors.password}
                placeholder={t("Your password")}
              />

              <button
                className="btn btn-block m-b-10"
                type="submit"
                disabled={loading}
              >
                {t("Submit and access account")}
              </button>
            </Form>
          )}
        </AuthForm>
      </div>

      <AuthFooter />
    </div>
  );
};

NewMemberPassword.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default NewMemberPassword;
