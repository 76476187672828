import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import {COLORS, DOMAIN_STATUSES, ICONS_NAMES} from "../../../constants/common";
import Icon from "../../common/icon";


const DomainsStatus = ({ domainStatus, onChange }) => {
    switch (domainStatus) {
        case DOMAIN_STATUSES.ACTIVE:
            return  <span className="domain-status active">Active primary domain</span>
        case DOMAIN_STATUSES.VALID:
            return <div
            className="switch-active-domain"
            role="presentation"
            onClick={onChange}
        >
            <Icon color={COLORS.DARK} name={ICONS_NAMES.EXCHANGE} />
            <span className="domain-status switch" >Make primary domain</span>
        </div>
        default:
            return <span className="domain-status pending">Validation pending</span>
    }
};

DomainsStatus.propTypes = {
    domainStatus: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DomainsStatus;
