import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../loader";
import { FILES_TYPES, UPLOAD_FILE_STATUS } from "../../../constants/common";
import LoadingProgress from "../loadingProgress";
import "./style.scss";
import { uiTypes } from "../../../store/ui";

const FileUpload = ({
  name,
  accept,
  onChange,
  onDelete,
  className,
  classNameBtn,
  type,
  btnText,
  withPreview,
  infoText,
  loading,
  title,
  withLoader,
  progress,
  withDeleteButton,
  logoSrc,
  showWhenFileAdded,
  disabled,
  errorText,
  validate,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputEl = useRef(null);
  const [file, setFile] = useState(null);

  const changeHandler = (event) => {
    const [addedFile] = event.target?.files;
    if (addedFile) {
      const fileFormat = addedFile.type;
      if (validate && !accept.includes(fileFormat)) {
        dispatch({
          type: uiTypes.SET_ERROR,
          payload: t(errorText),
        });
        return;
      }
      setFile(addedFile);
      onChange(event);
      inputEl.current.value = "";
    }
  };

  const deleteHandler = () => {
    if (onDelete && typeof onDelete === "function") onDelete(name);
    if (inputEl.current) inputEl.current.value = null;
    setFile(null);
  };

  const fileDataExist = logoSrc || file !== null;
  const showUploadedFile = showWhenFileAdded || file === null;
  const showLoader =
    withLoader &&
    progress !== UPLOAD_FILE_STATUS.START &&
    progress !== UPLOAD_FILE_STATUS.DONE;

  if (loading) {
    return (
      <div>
        <Loader secondary />
        <p className="m-t-10 text-center">Please wait...</p>
      </div>
    );
  }

  return (
    <div className={`FileUpload ${className}`}>
      {withPreview && (
        <div className="FileUpload_preview">
          {fileDataExist && (
            <img
              src={file ? URL.createObjectURL(file) : logoSrc}
              alt="preview"
            />
          )}
        </div>
      )}

      <div className="FileUpload_wrap">
        {showLoader ? (
          <LoadingProgress done={progress} />
        ) : (
          <>
            {withDeleteButton && file && file.name && (
              <div className="btn m-b-10 btn-white btn-notHovered">
                <span className="FileUpload_text-preview">{file.name}</span>
                <button
                  aria-label="delete"
                  onClick={deleteHandler}
                  className="delete_file"
                />
              </div>
            )}
            {title && <h4 className="upload_title">{title}</h4>}
            {showUploadedFile && (
              <label
                className={`btn m-b-10 ${
                  file && file.name && "d-none"
                } ${classNameBtn}`}
              >
                <input
                  disabled={disabled}
                  type="file"
                  name={name}
                  accept={accept}
                  onChange={changeHandler}
                  ref={inputEl}
                />
                <span>{btnText || `Click to upload ${type}`}</span>
              </label>
            )}

            {infoText && (
              <span className="info-text text-center">{infoText}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  name: "",
  accept: [
    FILES_TYPES.VIDEO.MP4,
    FILES_TYPES.IMAGE.PNG,
    FILES_TYPES.IMAGE.JPEG,
    FILES_TYPES.TEXT,
  ],
  className: "",
  classNameBtn: "btn-white",
  btnText: "",
  infoText: null,
  type: FILES_TYPES.IMAGE,
  onChange: () => {},
  onDelete: null,
  withPreview: false,
  loading: false,
  title: "",
  withLoader: false,
  progress: 0,
  withDeleteButton: true,
  logoSrc: "",
  showWhenFileAdded: true,
  disabled: false,
  errorText: "Incorrect file type",
  validate: false,
};
FileUpload.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  classNameBtn: PropTypes.string,
  btnText: PropTypes.string,
  infoText: PropTypes.string,
  type: PropTypes.oneOf([
    FILES_TYPES.VIDEO.DEFAULT,
    FILES_TYPES.IMAGE,
    FILES_TYPES.TEXT,
  ]),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  withPreview: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  withLoader: PropTypes.bool,
  progress: PropTypes.number,
  withDeleteButton: PropTypes.bool,
  logoSrc: PropTypes.string,
  showWhenFileAdded: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  validate: PropTypes.bool,
};

export default FileUpload;
