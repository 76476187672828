import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {  Field, useFormikContext, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import "./style.scss";

const Input = ({
  name,
  type,
  error,
  touched,
  className,
  checkBoxLabel,
  onBlur,
  ...props
}) => {
  
  // CONST 
  const CHECKBOX = "checkbox";

  // HOOKS
  const serverErrors = useSelector(({ ui }) => ui.authErrors);
  const { setErrors } = useFormikContext();

  useEffect(() => {
    if (
      serverErrors &&
      serverErrors[name] &&
      setErrors &&
      typeof setErrors === "function"
    ) {
      setErrors({ [name]: serverErrors[name] });
    }
  }, [serverErrors]);

  // FUNCTIONS
  const onBlurFunction = () =>{
    if(typeof onBlur === 'function') {
      onBlur()
    } 
  }

  return (
    <div className={`form-control m-b-20 ${className}`}>
      <div
        className={`form-control-input input-${type} ${
          error && touched && "form-control-input-error"
        }`}
      >
        {type === CHECKBOX ? (
          <label htmlFor={`${name}`}>
            <Field id={`${name}`} name={name} type={type} {...props} />
            <span className={CHECKBOX} />
            {checkBoxLabel && <span>{checkBoxLabel}</span>}
          </label>
        ) : (
          <Field 
            autoComplete="off" 
            name={name} 
            type={type} 
            onBlur={onBlurFunction}
            {...props} />
        )}
      </div>
      <ErrorMessage className="form-error" name={name} component="div" />
    </div>
  );
};

Input.defaultProps = {
  error: null,
  className: "",
  checkBoxLabel: null,
  touched: false,
  onBlur: null,
  type: "text"
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  checkBoxLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  onBlur:  PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.any,
  ])
};

export default Input;
