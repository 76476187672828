const buttonUrlValidator = (buttonUrl) => {
  if (!/(http(s?)):\/\//i.test(buttonUrl)) {
    return `https://${buttonUrl}`;
  }
  return buttonUrl;
};

const getBlobVideoType = (blobType) => {
  const [blobVideoType] = blobType.split(";");
  return blobVideoType;
};

export { buttonUrlValidator, getBlobVideoType };
