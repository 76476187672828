/* eslint-disable no-useless-escape */
import * as yup from "yup";

const linkReg = /^[\w\-\._~:/?#[\]@!\$&'\{\}\*\,;=.]+$/;
const errMessage = "Enter correct url!";
export const domainSchema = yup.object().shape({
  custom_video_url: yup.string().matches(linkReg, errMessage),
  custom_url: yup.string().matches(linkReg, errMessage),
  custom_preview_url: yup.string().matches(linkReg, errMessage)
});
