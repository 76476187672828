const FILES_TYPES = {
  VIDEO: {
    DEFAULT: "video",
    MP4: "video/mp4",
    WEBM: "video/webm",
  },
  IMAGE: {
    JPEG: "image/jpeg",
    PNG: "image/png",
  },
  TEXT: "text",
};

const FORM_INITIAL_VALUES = {
  WELCOME_MESSAGE: "Example:\n \n Hi, {{Firstname}}, this is a quick video\n",
  CREATE_PAGE: {
    TITLE: `Example:

Increase your conversion with personalization, {{Firstname}}!`,
    BODY: `Example:

You are using sales videos on landing pages at {{Company}} in {{Location}}.

The results are great but the conversion rate can’t be high enough, right?

Personalize your landing page with already existing data on your leads in a few clicks will help skyrocket your CR!

We’ve helped clients like {{Client1}} or {{Client2}} with our no-bullshit page builder for sales reps who are busy selling and not with complicated tools.`,
  },
};

const DEFAULT_SELECT_VARIABLES = [
  { name: "FirstName", value: "{{FirstName}}" },
  { name: "Company", value: "{{Company}}" },
  { name: "Location", value: "{{Location}}" },
  { name: "Custom (must fit CSV)", value: "{{}}" },
];

const TABS_CLASSES = {
 COMPLETE_CLASS: "complete",
 DISABLED_CLASS: "disabled",
 PROGRESS_CLASS: "progress",
}

const LEADS_URL = "leads";
const VIDEOS_URL = "videos";
const PUBLISH_URL = "publish";
const LANDING_PAGES_URL = "landing-pages";

const START_ROUTE_LINK = "/campaigns";
const REDIRECT_CAMPAIGN_FAIL = "/campaigns/not-found";

const UPLOAD_FILES_TYPES = {
  LOGO: "image",
  CSV: "csv",
  VIDEO: "video",
};

const UPLOAD_FILE_STATUS = {
  START: 0,
  DONE: 100,
};

const CAMPAIGN_STATUSES = {
  draft: { id: 1, name: "Draft campaigns", type: "draft" },
  active: { id: 5, name: "Active campaigns", type: "active" },
  inactive: { id: 10, name: "Inactive campaigns", type: "inactive" },
};

const CAMPAIGN_STATUS = {
  1: "draft",
  5: "active",
  10: "inactive",
};

const CAMPAIGN_VIDEO_TYPES = {
  none: 0,
  generic: 1,
  each: 2,
};

const CAMPAIGN_STEPS = {
  0: "leads",
  1: "videos",
  2: "landing-pages",
  3: "publish",
  4: "publish", // For CampaignListItem as "PUBLISH_COMPLETE"
};

const CAMPAIGN_STEPS_VALUE = {
  LEADS: 0,
  VIDEOS: 1,
  LANDING_PAGES: 2,
  PUBLISH: 3,
  PUBLISH_COMPLETE: 4,
};

const RECORDER_STATUS = {
  RECORDING: "recording",
  READY: "ready",
};

const ICONS_NAMES = {
  MICROPHONE: "microphone",
  VOICE: "voice",
  CROSS_CIRCLE: "cross-circle",
  ARROW_RIGHT: "arrow-right",
  RECORD: "record",
  LANDING_PAGES: "landing-pages",
  CAMERA: "camera",
  VIDEO_LIBRARY: "video-library",
  LANDING_LIBRARY: "landing-library",
  STATICS: "statics",
  INTEGRATE: "integrate",
  PLAY: "play",
  LOGOUT: "logout",
  MORE: "more",
  PROFILE: "profile",
  SUPPORT: "support",
  SETTINGS: "settings",
  BILLING: "billing",
  TEAM_MEMBERS: "team-members",
  EDIT: "edit",
  PHONE: "phone",
  EMAIL: "email",
  CAMPAIGNS: "campaigns",
  ROUND_ARROW: "round-arrow",
  SMILE: "smile",
  SCREEN_RECORD: "screen-record",
  CAMERA_OUTLINE: "camera-outline",
  TICK: "tick",
  DROPDOWN: "dropdown",
  RECORD_AGAIN: "record-again",
  SAVE: "save",
  CLOSE: "close",
  DOMAINS: "domains",
  DOWNLOAD: "download",
  EXCHANGE: "exchange",
  ASC: "asc",
  DESC: "desc",
  LEADS: "leads",
  CROSS: "cross",
  TICK_NEW: "tick_new",
  SEARCH: "search",
  COPY: "copy",
  SAD: "sad",
  MASTERCARD: "mastercard"
};

const VIDEO_RATIO_16_9 = 1.777777778;

const TERMS_PRIVACY_LINK = "https://parruu.com/terms-conditions";

const CONTACT_IMPRINT_LINK = "https://parruu.com/contact/";

const CSV_EXAMPLE_NAME = "parruu-lead-template.csv";

const VIDEO_WEBM = "video/webm;codecs=vp8,opus";
const VIDEO_MP4 = "video/mp4";

const OBJECT_KEY = {
  FIRST: 0,
  SECOND: 1,
};

const CREATE_DOMAIN_STEP = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3
};

const DOMAIN_TYPE = {
  SPARE: 1,
  SIMPLE: 2,
}

const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE"
};

const SORT_BY = {
  ASC: "asc",
  DESC: "desc"
};

const PARAMS_STATUS = {
  TRUE:1,
  FALSE:0
}



const LANDING_FORM_PRIORITY = {
  image: 1,
  title: 2,
  content: 3,
  button_title: 4,
  button_url: 5,
};

const CUSTOM_DOMAIN = {
  STATUS: {
    ACTIVE: 1,
    INACTIVE: 0
  },
  VALIDATION: {
    VALID: 1,
    NOT_VALID: 0
  }
}

const DOMAIN_STATUSES = {
  ACTIVE: "active",
  PENDING: "pending",
  VALID: "valid"
}

const COLORS = {
  RED: "#ff3f3f",
  BLUE: "#1f86ff",
  BLUE_LIGHT: "#cae3ff",
  DARK: "#393f4d",
  GREY: "#888f9e",
  ORANGE: "#ffbd33",
  GREEN: "#7ed321",
  DARK_GREEN: "rgba(126, 211, 33, 0.43)"
}

const MODALS_TEXTS = {
  DELETE_ALL_LEADS:  "Are you sure you want to delete your list? If you delete your list all your video and landing pages are lost and you need to start your campaign again",
  DELETE_LAST_LEAD: "Are you sure you want to delete this lead? If you delete it, your campaign will be deleted.",
  DELETE_LEAD: "Are you sure you want to delete this lead?",
  MODAL_BILLING_LEAVE: {
    FIRST_LINE: "If you want to leave us, it means, that we didn’t do our job right…",
    SECOND_LINE: "Last try to convince you to stay"
  },
  MODAL_BILLING_SWITCH: {
    TITLE: "Would you like to switch to the \n „{{title}}“ plan now?",
    ADDITIONAL_INFO: "By confirming you agree to the subscription ",
    TERMS_CONDITIONS: "terms & conditions"
  }
}

const MODALS_DIALOGS = {
  YES: "Yes",
  NO: "No",
  NOT_NOW: "Not now",
  ARE_YOU_SURE: "Are you sure"
}

const USER_TYPE = {
  USER: 1,
  MEMBER: 2
}
const USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0
}
export {
  ICONS_NAMES,
  UPLOAD_FILE_STATUS,
  UPLOAD_FILES_TYPES,
  FILES_TYPES,
  FORM_INITIAL_VALUES,
  DEFAULT_SELECT_VARIABLES,
  START_ROUTE_LINK,
  CAMPAIGN_STATUSES,
  CAMPAIGN_VIDEO_TYPES,
  CAMPAIGN_STEPS,
  RECORDER_STATUS,
  CAMPAIGN_STEPS_VALUE,
  VIDEO_RATIO_16_9,
  TERMS_PRIVACY_LINK,
  CONTACT_IMPRINT_LINK,
  OBJECT_KEY,
  CSV_EXAMPLE_NAME,
  REDIRECT_CAMPAIGN_FAIL,
  VIDEO_MP4,
  VIDEO_WEBM,
  TABS_CLASSES,
  LEADS_URL,
  VIDEOS_URL,
  PUBLISH_URL,
  LANDING_PAGES_URL,
  LANDING_FORM_PRIORITY,
  API_METHODS,
  DOMAIN_TYPE,
  CREATE_DOMAIN_STEP,
  CUSTOM_DOMAIN,
  DOMAIN_STATUSES,
  MODALS_DIALOGS,
  COLORS,
  MODALS_TEXTS,
  SORT_BY,
  CAMPAIGN_STATUS,
  PARAMS_STATUS,
  USER_TYPE,
  USER_STATUS
};
