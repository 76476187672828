import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const RecordingPreview = ({ stream }) => {
  const videoPreviewRef = useRef();
  useEffect(() => {
    if (videoPreviewRef.current && stream) {
      videoPreviewRef.current.srcObject = stream;
    }
  }, []);

  return (
    <div className="video_wrapper">
      <div className="video_wrapper__container">
        <video ref={videoPreviewRef} autoPlay className="video" poster="">
          <track kind="captions" />
        </video>
      </div>
    </div>
  );
};
RecordingPreview.propTypes = {
  stream: PropTypes.shape(MediaStream).isRequired,
};
export default RecordingPreview;
