import React, { useCallback } from "react";
import { Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AuthFooter, AuthHeader, Input } from "../../../components";
import { AuthForm } from "../../../containers";
import { ROUTES } from "../../../constants/routes";
import { recoveryPasswordRequest } from "../../../store/user/operation";
import { resetPasswordValidator } from "./resetPasswordValidator";

const ResetPassword = ({ location: { search } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const id = new URLSearchParams(search).get("id");
  const code = new URLSearchParams(search).get("code");

  const loading = useSelector(({ ui }) => ui.requestLoading);

  const forgotPassHandler = useCallback(
    (values) => {
      dispatch(recoveryPasswordRequest({ ...values, code, id }));
    },
    [id, code]
  );

  return (
    <div className="Auth">
      <AuthHeader buttonData={{ text: "Sign In", link: ROUTES.LOGIN }} />

      <div className="Auth_content">
        <AuthForm
          title={t("Please enter a new password")}
          onSubmit={forgotPassHandler}
          initialValues={{ password: "" }}
          validate={resetPasswordValidator}
        >
          {({ errors }) => (
            <Form>
              <Input
                className="m-b-20"
                type="password"
                name="password"
                error={errors.password}
                placeholder={t("New Password")}
              />

              <button
                className="btn btn-block m-b-10"
                type="submit"
                disabled={loading}
              >
                {t("Submit")}
              </button>
              <p className="Auth_content__link text-center">
                {t("We’ll send you a link to reset your password")}
              </p>
            </Form>
          )}
        </AuthForm>
      </div>

      <AuthFooter />
    </div>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default ResetPassword;
