import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import CustomSelect from "../customSelect";

const TextArea = ({
  name,
  className,
  onChange,
  value,
  rows,
  placeholder,
  maxLength,
  variables,
  defaultValue,
  withInsertTimestamp,
  insertButtonName,
  error,
  id,
  insertValue,
  disabled,
  type,
}) => {
  const [valueField, setValueFields] = useState(value);
  const ref = useRef(null);
  const { current } = ref;
  let cursorPosition = current?.selectionStart || 0;
  const TEXTAREA = "textarea"
  useEffect(() => setValueFields(value), [value]);

  const rememberCursorOnClick = ({
    target: { selectionStart, selectionEnd },
  }) => {
    if (selectionStart === selectionEnd) {
      cursorPosition = selectionStart;
      current?.setSelectionRange(selectionStart, selectionEnd);
    }
  };

  const onChangeValue = (event) => {
    const {
      target: { value: eventValue, selectionStart },
    } = event;

    if (value.length <= maxLength) {
      if (onChange ?? typeof onChange === "function") {
        onChange(event);
        cursorPosition = selectionStart;
        current?.setSelectionRange(cursorPosition, cursorPosition);
        setValueFields(eventValue);
      }
    }
  };

  const onInsertValue = (event) => {
    if (event) {
      const {
        target: { value: eventValue },
      } = event;
      if (current) {
        const { value: fieldValue, selectionStart: textareaStart } = current;
        cursorPosition = textareaStart;
        const textBeforeCursor = fieldValue.substring(0, cursorPosition);
        const textAfterCursor = fieldValue.substring(
          cursorPosition,
          fieldValue.length
        );
        const textValue = textBeforeCursor + eventValue + textAfterCursor;
        const result = { target: { name, value: textValue } };
        if (textValue.length < maxLength) {
          current.selectionStart = textBeforeCursor.length + eventValue.length;
          onChange(result);
          setValueFields(textValue);
        }
      } else onChangeValue(event);
    }
  };

  const onInsertButtonClick = () =>
    onInsertValue({
      target: { value: insertValue },
    });


  return (
    <label htmlFor={id} className={`TextArea ${className}`}>
      <span className="counter">
        {valueField.length}/{maxLength}
      </span>
      {type === TEXTAREA ? (
        <>
          {error && <span className="text-red">{error}</span>}
          <textarea
            maxLength={maxLength}
            id={id}
            onClick={rememberCursorOnClick}
            ref={ref}
            name={name}
            rows={rows}
            onChange={onChangeValue}
            value={valueField}
            placeholder={placeholder}
          />
        </>
      ) : (
        <>
          <input
            className={className}
            maxLength={maxLength}
            autoComplete="off"
            onClick={rememberCursorOnClick}
            ref={ref}
            type="text"
            name={name}
            value={valueField}
            onChange={onChangeValue}
            placeholder={placeholder}
          />
          {error && <span className="text-red">{error}</span>}
        </>
      )}
      <div className="select-wrapper">
        {variables && (
          <CustomSelect
            disabled={disabled}
            resetToDefault
            defaultValue={defaultValue}
            onChange={onInsertValue}
            values={variables}
            name={name}
          />
        )}
        {withInsertTimestamp && (
          <button
            className="insert-button"
            onClick={onInsertButtonClick}
            type="button"
          >
            {insertButtonName}
          </button>
        )}
      </div>
    </label>
  );
};

TextArea.defaultProps = {
  name: "",
  className: "",
  onChange: () => {},
  value: "",
  rows: 4,
  maxLength: 100,
  placeholder: "Enter the text",
  defaultValue: "",
  withInsertTimestamp: false,
  insertButtonName: "insert",
  error: "",
  id: "",
  insertValue: "",
  disabled: false,
  type: "textarea",
};

TextArea.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  insertButtonName: PropTypes.string,
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  withInsertTimestamp: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  insertValue: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

export default TextArea;
