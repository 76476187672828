import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const EditLeadButtonsBlock = ({leadDataWasChanged, onSave, onCancel, id, fields}) => {
    const { t } = useTranslation();
     return  leadDataWasChanged
         ? <button className="save-button" onClick={() => onSave(id, fields)}>{t("Save")}</button>
         : <button className="btn btn-outline-dark save-button cancel-button" onClick={onCancel}>{t("Cancel")}</button>
};

EditLeadButtonsBlock.propTypes = {
    leadDataWasChanged: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    fields: PropTypes.shape({}).isRequired

};

export default EditLeadButtonsBlock;