import types from "./types";
import apiUrls from "../../constants/apiUrls";
import {uiAction, uiTypes} from "../ui";
import apiHelper from "../../utils/helpers/apiHelper";
import {API_METHODS} from "../../constants/common";
import {getResErrMessage} from "../../utils/helpers/responseHelpers";
import {setDomains} from "./action";

const createDomainRequest = (data) => ({
  type: types.CREATE_DOMAIN,
  payload: {
    request: {
      method: "POST",
      url: apiUrls.customDomains,
      data,
    },
  },
});

const updateDomainRequest = (id, data) => {
  return {
    type: types.CREATE_DOMAIN,
    payload: {
      request: {
        method: "PUT",
        url: `${apiUrls.customDomains}/${id}`,
        data,
      },
    },
  };
};

const getDomainRequest = (id) => {
  return {
    type: types.CREATE_DOMAIN,
    payload: {
      request: {
        method: "GET",
        url: `${apiUrls.customDomains}/${id}`,
      },
    },
  };
};

const getDomainsRequest = (data) => ({
  type: types.GET_DOMAINS,
  payload: {
    request: {
      method: "GET",
      url: apiUrls.customDomains,
      data,
    },
  },
});

const deleteDomainRequest = (id) => async (dispatch) => {
  dispatch(uiAction.requestLoading(true));
  try {
     await apiHelper({
      requestUrl:`${apiUrls.customDomains}/${id}`,
      method: API_METHODS.DELETE
    })()

    const data = await apiHelper({
      requestUrl: apiUrls.customDomains,
      method: API_METHODS.GET
    })();

    dispatch(setDomains(data))
    dispatch(uiAction.requestLoading(false));
  }
  catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
  }
}

const validateDomainsRequest = (id) => async (dispatch) => {
  dispatch(uiAction.requestLoading(true));

  try {
      await apiHelper({
        requestUrl: `${apiUrls.customDomains}/${id}/validate`,
        method: API_METHODS.PUT
      })()

    dispatch(uiAction.requestLoading(false));
  }
  catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
  }
}

const activateDomainRequest = (id) => async (dispatch) => {
  dispatch(uiAction.requestLoading(true));
  try {
      await apiHelper({
        requestUrl: `${apiUrls.customDomains}/${id}/activate`,
        method: API_METHODS.PUT
      })()

    const domains = await apiHelper({
      requestUrl: apiUrls.customDomains,
      method: API_METHODS.GET
    })();

    dispatch(setDomains(domains))
    dispatch(uiAction.requestLoading(false));
  }
  catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
  }
}



export {
  deleteDomainRequest,
  createDomainRequest,
  updateDomainRequest,
  getDomainsRequest,
  getDomainRequest,
  validateDomainsRequest,
  activateDomainRequest,
};
