import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { useTranslation } from "react-i18next";

const LoadingProgress = ({ done, className }) => {
  const { t } = useTranslation();
  return (
    <div className={`progress-container ${className}`}>
      <p className="text">
        {t("Your video is being uploaded. This takes a few seconds.")}
      </p>
      <div className="progress_wrapper">
        <div className="progress">
          <div className="progress-done" style={{ width: `${done}%` }} />
        </div>
        <p className="percent">{done}%</p>
      </div>
    </div>
  );
};

LoadingProgress.defaultProps = { className: "" };
LoadingProgress.propTypes = {
  done: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default LoadingProgress;
