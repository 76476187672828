import React from "react";
import { Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AuthFooter, AuthHeader, Input } from "../../../components";
import { AuthForm } from "../../../containers";
import { ROUTES } from "../../../constants/routes";
import { forgotPasswordValidator } from "./forgotPasswordValidator";
import { forgotPasswordRequest } from "../../../store/user/operation";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(({ ui }) => ui.requestLoading);
  const forgotPassHandler = (values) => {
    dispatch(forgotPasswordRequest(values));
  };

  return (
    <div className="Auth">
      <AuthHeader buttonData={{ text: "Sign In", link: ROUTES.LOGIN }} />

      <div className="Auth_content">
        <AuthForm
          title={t("Forgot Password")}
          onSubmit={forgotPassHandler}
          validate={forgotPasswordValidator}
          initialValues={{ email: "" }}
        >
          {({ errors }) => (
            <Form>
              <Input
                type="password-recovery-email"
                className="m-b-20"
                name="email"
                error={errors.email}
                placeholder={t("E-mail address")}
              />

              <button
                className="btn btn-block m-b-10"
                type="submit"
                disabled={loading}
              >
                {t("Submit")}
              </button>
              <p className="Auth_content__link text-center">
                {t("We’ll send you a link to reset your password")}
              </p>
            </Form>
          )}
        </AuthForm>
      </div>

      <AuthFooter />
    </div>
  );
};

export default ForgotPassword;
