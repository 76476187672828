import React, { useEffect, useState } from "react";
import {Link, NavLink} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import MainRoutes from "../../routes/mainRoutes";
import { ROUTES } from "../../constants/routes";
import { userOperation } from "../../store/user";
import { useModal } from "../../utils/helpers/useModal";
import Logo from "../../assets/logo.svg";
import { uiOperation } from "../../store/ui";
import { Icon, Loader, NavDropdown } from "../../components";
import ModalContainer from "../modal";
import "./style.scss";
import {
  CONTACT_IMPRINT_LINK,
  TERMS_PRIVACY_LINK,
} from "../../constants/common";

const Main = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isMobMenuVisible, setMobMenuVisible] = useState(false);
  const menus = useSelector(({ ui }) => ui.menus.main);
  const currentUser = useSelector(({ user: { user } }) => user);
  const {toggleModal, isOpenModal} = useModal();

  const isDomainsPage = location.pathname === ROUTES.DOMAINS;

  const logoutHandler = () => {
    dispatch(userOperation.signOut());
  };

  useEffect(() => {
    dispatch(uiOperation.getMenuRequest());
  }, [uiOperation.getMenuRequest]);

  useEffect(() => {
    if (!currentUser || !currentUser) {
      dispatch(userOperation.getCurrentUserRequest());
    }
  }, [currentUser, userOperation.getCurrentUserRequest]);

  if (!currentUser) return <Loader />;

  return (
    <div className="Main">
      <header>
        <button
          className={`mobMenuBtn ${isMobMenuVisible && "opened"}`}
          aria-label="toggle menu"
          type="button"
          onClick={() => setMobMenuVisible(!isMobMenuVisible)}
        />
        {isMobile ? (
          <p className="tablet-title">
            {t(
              "Our beta version doesn't support mobile or tablet devices. Please use a desktop device."
            )}
          </p>
        ) : (
          <p className="welcome">{
            t(isDomainsPage? `Welcome back, ${currentUser.first_name}!`: "Beta Testing Version")
          }
          </p>
        )}
        <NavLink to="/" className="logo-link">
          <img className="logo" src={Logo} alt="Logo" />
        </NavLink>
        <NavDropdown logout={toggleModal} />
      </header>
      <nav className={`Main_nav ${isMobMenuVisible && "showMobMenu"}`}>
        <NavLink to="/" className="logo-link">
          <img className="logo" src={Logo} alt="Logo" />
        </NavLink>
        <ul>
          {menus &&
            menus.length &&
            menus.map((menu) => (
              <li key={`routeKey-${menu.title.trim()}`}>
                {menu.highlight ? (
                  <span className="disabled">
                    <Icon
                      className="icon"
                      name={menu.block_type}
                      color="#888F9E"
                    />
                    <span>{t("{{title}}", { title: menu.title })}</span>
                    <span className="comingSoon">{t("Coming soon")}</span>
                  </span>
                ) : (
                  <NavLink to={`${menu.url}`} activeClassName="active">
                    <Icon className="icon" name={menu.block_type} />
                    <span>{t(menu.title)}</span>
                  </NavLink>
                )}
              </li>
            ))}
        </ul>
      </nav>
      <main>
        <MainRoutes />
      </main>
      <footer>
        <p>{t("© 2019-2021 by parruu AG")}</p>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href={TERMS_PRIVACY_LINK}>
              {t("T & C")}
            </a>
          </li>
          <li>
            <Link to={ROUTES.SUPPORT}>{t("Support")}</Link>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href={CONTACT_IMPRINT_LINK}>
              {t("Contact & Imprint")}
            </a>
          </li>
        </ul>
      </footer>
      <ModalContainer isOpen={isOpenModal} closeModal={toggleModal}>
        <h3 className="modal_confirmation__body__title">
          {t("Are you sure you want to log out?")}
        </h3>
        <div className="modal_confirmation__body__buttons_wrapper">
          <button className="btn btn-blue" onClick={logoutHandler}>
            {t("Yes")}
          </button>
          <button className="btn btn-blue" onClick={toggleModal}>
            {t("No")}
          </button>
        </div>
      </ModalContainer>
    </div>
  );
};

Main.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
};

export default Main;
