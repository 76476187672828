import React, { useContext, useEffect, useState } from "react";
import useMediaRecorder from "@wmik/use-media-recorder";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCampaignAndLeads } from "../../../../utils/helpers/campaignHooks";
import {
  createCampaignVideoRequest,
  deleteCampaignVideo,
} from "../../../../store/campaigns/operation";
import { useCountdown } from "../../../../store/ui/hooks";
import VideoControls from "../videoControls";
import SelectRecordOption from "../selectRecordOption";
import FileUpload from "../../../common/fileUpload";
import VideoPlayer from "../../../common/videoPlayer";
import RecordingPreview from "../recordingPreview";
import WelcomeMessageForm from "../welcomeMessageForm";
import { campaignsOperation } from "../../../../store/campaigns";
import {
  CAMPAIGN_STEPS_VALUE,
  RECORDER_STATUS,
  CAMPAIGN_STEPS,
  ICONS_NAMES,
  VIDEO_RATIO_16_9,
  CAMPAIGN_VIDEO_TYPES,
  VIDEO_WEBM,
  CAMPAIGN_STATUSES,
} from "../../../../constants/common";
import Icon from "../../../common/icon";
import ModalContainer from "../../../../containers/modal";
import { CampaignContext } from "../../../../store/ui/context";
import { getBlobVideoType } from "../../../../utils/helpers/stringHelper";
import { checkRecordingExceptions } from "../../../../utils/helpers/common";
import { useModal } from "../../../../utils/helpers/useModal";
import "./style.scss";

const VideoGenericTypeScreen = () => {
  const { isShowForm, setIsShowForm } = useContext(CampaignContext);
  const countdown = useCountdown();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { campaign } = useCampaignAndLeads();

  const RECORDING_DELAY = 500;

  const { leads } = useSelector(({ campaigns }) => ({
    leads: campaigns.leads,
  }));

  const {toggleModal, isOpenModal} = useModal();

  const [recordScreen, setRecordScreen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const onStopMediaRecorder = (blob) => {
    const videoFile = new File([blob], `campaign-${campaign.id}.mp4`, {
      type: getBlobVideoType(blob.type),
    });
    setCurrentVideo(videoFile);
  };

  const {
    status,
    liveStream,
    stopRecording,
    startRecording,
    getMediaStream,
  } = useMediaRecorder({
    recordScreen,
    mediaStreamConstraints: {
      video: {
        aspectRatio: { ideal: VIDEO_RATIO_16_9 },
      },
      audio: true,
    },
    mediaRecorderOptions: {
      mimeType: checkRecordingExceptions() ? VIDEO_WEBM : "",
    },
    onStop: onStopMediaRecorder,
  });

  const startRecordingHandler = () => {
    if (recordScreen) getMediaStream();
    else {
      getMediaStream();
      countdown.start().then(() => {
        startRecording();
      });
    }
  };

  useEffect(() => {
    if (recordScreen && status === RECORDER_STATUS.READY) {
      countdown.start().then(() => {
        // For skip first video frames, because it's record counter
        setTimeout(() => startRecording(), RECORDING_DELAY);
      });
    }
  }, [status]);

  const stopRecordingHandler = () => {
    stopRecording();
  };

  const firstLeadId = leads && leads[0]?.id;
  const firstLeadWithoutVideoId = leads?.find((item) => !item.video_id)?.id;
  
  const switchVideoType = () => {
    if (campaign) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          video_type: CAMPAIGN_VIDEO_TYPES.each,
        })
      );
      history.push(`/campaigns/${campaign.id}/videos/${firstLeadWithoutVideoId || firstLeadId}`);

    }

  };

  const clearCurrentVideo = () => {
    if (campaign.video_id) {
      dispatch(deleteCampaignVideo(campaign.id, campaign.video_id));
    }
    setCurrentVideo(null);
  };

  const changeRecordOption = (isRecordScreen) =>
    setRecordScreen(isRecordScreen);

  const deleteWelcomeMessage = () => {
    if (campaign.welcome_text) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          welcome_text: "",
        })
      );
    }
    setIsShowForm(false);
  };

  const onNextLeadHandler = () => {
    if (currentVideo) {
      dispatch(createCampaignVideoRequest(currentVideo, campaign));
    }
    setCurrentVideo(null);
  };

  const goToLandingsHandler = () => {
    if (campaign?.step !== CAMPAIGN_STEPS_VALUE.LANDING_PAGES) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.LANDING_PAGES,
        })
      );
    }
    history.push(
      `/campaigns/${campaign.id}/${
        CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.LANDING_PAGES]
      }`
    );
  };

  const isSingleLead = leads?.length <= 1;
  const currentLeadVideoSrc =
    campaign.video &&
    campaign.video.video_file_id &&
    `${process.env.REACT_APP_API_URL}/uploads/videos/${campaign.video.video_file_id.filename}`;

  const videoSrc = !currentVideo && currentLeadVideoSrc ? currentLeadVideoSrc : ""

  useEffect(() => {
    if (currentVideo) {
      onNextLeadHandler();
    }
  }, [currentVideo]);

  return (
    <div className="video">
      <div className="video__container">
        <div className="video__container__controls">
          <h1 className="title">
            {isSingleLead
              ? t("Record or upload a video")
              : t("Record or upload a generic video for all leads")}
          </h1>
          <VideoControls
            videoWasRecorded={campaign?.video_id}
            status={status}
            onStartRecording={startRecordingHandler}
            onStopRecording={stopRecordingHandler}
            currentVideo={currentVideo}
          />
          <SelectRecordOption
            disabled={status === RECORDER_STATUS.RECORDING}
            onChange={changeRecordOption}
          />
          <FileUpload
            disabled={status === RECORDER_STATUS.RECORDING}
            title="or"
            withPreview={false}
            withDeleteButton={false}
            accept={["video/mp4"]}
            name="video-upload"
            type="video"
            onChange={(video) => setCurrentVideo(video.target.files[0])}
            btnText={t("Click to upload video")}
            infoText={t("only MP4 format, max. 200MB")}
            classNameBtn="btn-outline-dark"
          />
          {leads && !isSingleLead && (
            <div
              className="switch-video-type"
              role="presentation"
              onClick={toggleModal}
            >
              <Icon color="#393f4d" name={ICONS_NAMES.EXCHANGE} />
              <span>{t("Switch to one video for each lead")}</span>
            </div>
          )}
        </div>
        <div className="video__container__player">
          <h1 className="title m-b-25">
            <Icon
              name={ICONS_NAMES.VIDEO_LIBRARY}
              className="library-icon"
              size={30}
            />
            {t("Video for new campaign")}
          </h1>
          {status !== RECORDER_STATUS.RECORDING ? (
            <VideoPlayer
              isShowLoadingProgress
              showDeleteButton = {campaign.status === CAMPAIGN_STATUSES.draft.id}
              showWelcomeMessage
              onDeleteHandler={clearCurrentVideo}
              src={videoSrc}
              controls={false}
              welcomeMessage={
                campaign.welcome_text
                  ? campaign.welcome_text
                  : t("Optional welcome message here")
              }
            />
          ) : (
            <RecordingPreview stream={liveStream} />
          )}
          <div className="buttons-wrapper">
            {currentLeadVideoSrc && (
              <>
                {!isShowForm && (
                  <button
                    onClick={() => setIsShowForm(true)}
                    className="btn m-t-10"
                  >
                    {t("Add a welcome message")}
                  </button>
                )}
                {isShowForm && <WelcomeMessageForm campaign={campaign} />}
                <button
                  onClick={goToLandingsHandler}
                  className="btn btn-blue m-t-10"
                >
                  {t("Next to landing pages")}
                </button>
                {isShowForm && (
                  <button
                    onClick={deleteWelcomeMessage}
                    className="delete-welcome btn-outline-blue btn m-t-20"
                  >
                    <Icon
                      size={30}
                      name={ICONS_NAMES.CROSS_CIRCLE}
                      className="delete-list-icon"
                      color="#1f86ff"
                    />
                    {t("Delete welcome message")}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalContainer
        isOpen={isOpenModal}
        closeModal={toggleModal}
        onSubmit={switchVideoType}
        isConfirm
        btnText={{ yes: t("Yes"), no: t("No") }}
      >
        <h3 className="modal_confirmation__body__title">
          {t("Are you sure that you want to change the current option?")}
        </h3>
      </ModalContainer>
    </div>
  );
};

export default VideoGenericTypeScreen;
