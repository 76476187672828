import { lazy } from "react";
import { Page404, SupportPage } from "../pages";

const CampaignsLazy = lazy(() => import("../pages/main/сampaigns"));
const CampaignLazy = lazy(() => import("../pages/main/campaign"));
const VideoLibraryLazy = lazy(() => import("../pages/main/videoLibrary"));
const LandingPagesLazy = lazy(() => import("../pages/main/landingPages"));
const TeamMembersLazy = lazy(() => import("../pages/team-members"));
const ProfilePageLazy = lazy(() => import("../pages/profile"));
const DomainPageLazy = lazy(() => import("../pages/domain"));
const LeedsPagesLazy = lazy(() => import("../pages/main/leads"));
const BillingLazy = lazy(() => import("../pages/billing"));

export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  NEW_MEMBER_PASSWORD: "/new-member-password",
  DASHBOARD: "/dashboard",
  SUPPORT: "/support",
  DOMAINS: "/domains",
  CONTACT: "/contact",
  BILLING: "/billing",
  T_C: "t-c",
  MAIN: [
    {
      uid: "campaigns-pages",
      link: "/campaigns",
      component: CampaignsLazy,
      exact: true,
    },
    {
      uid: "campaign-page-edit",
      link: "/campaigns/:campaignId?",
      component: CampaignLazy,
      exact: false,
    },
    {
      uid: "landing-pages",
      link: "/landing-pages",
      component: LandingPagesLazy,
      exact: true,
    },
    {
      uid: "leads",
      link: "/leads",
      component: LeedsPagesLazy,
      exact: true,
    },
    {
      uid: "videos",
      link: "/videos",
      component: VideoLibraryLazy,
      exact: true,
    },
    {
      uid: "integrations",
      link: "/integrations",
      component: Page404,
      exact: true,
    },
    {
      uid: "reports",
      link: "/reports",
      component: Page404,
      exact: true,
    },
    {
      uid: "support",
      link: "/support",
      component: SupportPage,
      exact: true,
    },
    {
      uid: "domains",
      link: "/domains",
      component: DomainPageLazy,
      exact: true,
    },
    {
      uid: "profile",
      link: "/profile",
      component: ProfilePageLazy,
      exact: true,
    },
     {
      uid: "team-members",
      link: "/team-members",
      component: TeamMembersLazy,
      exact: true,
    },
    {
      uid: "billing",
      link: "/billing",
      component: BillingLazy,
      exact: true,
    },
  ],
};
