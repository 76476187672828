import React, {useMemo} from "react";
import PropTypes from "prop-types";
import Icon from "../../common/icon";
import {COLORS, CUSTOM_DOMAIN, DOMAIN_STATUSES, ICONS_NAMES} from "../../../constants/common";
import DomainsStatus from "../domainStatus";
import "./style.scss";

const COLOR = {
    [DOMAIN_STATUSES.ACTIVE]: COLORS.GREEN,
    [DOMAIN_STATUSES.VALID]: COLORS.DARK_GREEN,
    [DOMAIN_STATUSES.PENDING]: COLORS.ORANGE,
}

const DomainsListItem = ({ domain, deleteDomain, activateDomain, validateDomains }) => {

    const getDomainStatus = useMemo(
        () => {
            if (domain.is_active === CUSTOM_DOMAIN.STATUS.ACTIVE) return DOMAIN_STATUSES.ACTIVE
            if (domain.is_valid === CUSTOM_DOMAIN.VALIDATION.VALID) return DOMAIN_STATUSES.VALID
            return DOMAIN_STATUSES.PENDING
        }
    , [domain])

    const changeActiveDomain = () => activateDomain(domain.id)
    const handleDeleteDomain = () => deleteDomain(domain.id)
    const handleValidateDomain = () => validateDomains(domain.id)


  return (
          <li className="domains-list-item">
              <div className="domain-name">
                  <Icon
                      name={ICONS_NAMES.TICK}
                      size={40}
                      color={COLOR[getDomainStatus]}
                  />
                  <span>{domain.domain_name}</span>
              </div>
              <DomainsStatus
                  domainStatus={getDomainStatus}
                  onChange={changeActiveDomain}
              />
            <button
              onClick={handleDeleteDomain}
              className="delete-domain-button"
            >
              <Icon
                name={ICONS_NAMES.CROSS_CIRCLE}
                size={25}
                color={COLORS.DARK}
              />
              <span>Delete</span>
            </button>
              <button onClick={handleValidateDomain} className="validate-btn btn ">
                  Validate DNS settings
              </button>
          </li>

  );
};

DomainsListItem.propTypes = {
    domain: PropTypes.shape({
        id: PropTypes.number,
        is_active: PropTypes.number,
        is_valid: PropTypes.number,
        domain_name: PropTypes.string,
    }).isRequired,
    activateDomain: PropTypes.func.isRequired,
    validateDomains: PropTypes.func.isRequired,
    deleteDomain: PropTypes.func.isRequired,
};

export default DomainsListItem;
