import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { redirectTo } from "../../store/ui/action";
import "./styles.scss";

const Page404 = ({ text }) => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redirectPath = useSelector(({ ui }) => ui.redirectTo);

  useEffect(() => {
    if (redirectPath?.length) {
      dispatch(redirectTo(null));
    }
  }, [redirectPath]);

  return (
    <div className="page404">
      <h1>404</h1>
      <h4>{t("Page not found")}</h4>
      <p className="m-b-30">{t(text)}</p>
      <button className="btn" onClick={goBack}>
        {t("Go back")}
      </button>
    </div>
  );
};

Page404.defaultProps = {
  text: "Please try again later",
};
Page404.propTypes = {
  text: PropTypes.string,
};

export default Page404;
