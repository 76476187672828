import React from "react";
import PropTypes from "prop-types";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CampaignModel } from "../../../../utils/helpers/defPropTypes";
import CampaignTabsItem from "./campaignTabsItem"
import {
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
  CAMPAIGN_STATUSES,
  TABS_CLASSES,
  LEADS_URL,
  VIDEOS_URL,
  PUBLISH_URL,
  LANDING_PAGES_URL,
} from "../../../../constants/common";
import "./style.scss";

const CampaignTabs = ({ campaign }) => {
  // HOOKS
  const { leads } = useSelector(({ campaigns }) => ({
    leads: campaigns?.leads || [],
  }));
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const { state, pathname } = useLocation();

  // CONST
  const firstLeadId = leads && leads[0]?.id;
  const isGenericVideo = campaign?.video_id && campaign?.video_type === CAMPAIGN_VIDEO_TYPES.generic;
  const firstLeadWithoutVideoId = leads?.find((item) => !item?.video_id)?.id;
  const leadsPage = pathname.includes(LEADS_URL);
  const campaignDraft = campaign?.status === CAMPAIGN_STATUSES.draft.id;
  const isSingleLandingPage = leads?.length === 1

  // FUNCTIONS
  const correctVideoUrl = () => {
    if (campaign?.step > CAMPAIGN_STEPS_VALUE.VIDEOS) {
      return isGenericVideo ? VIDEOS_URL : `${VIDEOS_URL}/${firstLeadId}`;
    }
    if (campaign?.step === CAMPAIGN_STEPS_VALUE.VIDEOS ){
      if(campaign?.video_type === CAMPAIGN_VIDEO_TYPES.each) {
        return `${VIDEOS_URL}/${firstLeadWithoutVideoId || firstLeadId}`
      }
      return VIDEOS_URL;

    }
    return VIDEOS_URL; 
  };
  
  /* This is a quick solution, refactoring is required in the future */
  const checkClassStatus = (step) => {
    if (campaign) {
      if (campaignDraft) {
        switch (true) {
          case campaign?.step > step:
            return TABS_CLASSES.COMPLETE_CLASS;
          case campaign?.step === step:
            return TABS_CLASSES.PROGRESS_CLASS;
          default:
            return TABS_CLASSES.DISABLED_CLASS;
        }
      }
      if (step === CAMPAIGN_STEPS_VALUE.PUBLISH) {
        if (
          campaign?.step !== CAMPAIGN_STEPS_VALUE.PUBLISH_COMPLETE ||
          state?.isEdit
        )
          return TABS_CLASSES.PROGRESS_CLASS;
      }
      return TABS_CLASSES.COMPLETE_CLASS;
    }
    if (!campaign && step === CAMPAIGN_STEPS_VALUE.LEADS && leadsPage) {
      return TABS_CLASSES.PROGRESS_CLASS;
    }
    return TABS_CLASSES.DISABLED_CLASS;
  };

  return (
    <nav className="CampaignTabs">
      <CampaignTabsItem
        active={checkClassStatus(CAMPAIGN_STEPS_VALUE.LEADS)}
        campaignId={campaignId}
        label={t("leads")}
        to={LEADS_URL}
        state={state}
      />
      <CampaignTabsItem
        active={checkClassStatus(CAMPAIGN_STEPS_VALUE.VIDEOS)}
        campaignId={campaignId}
        label={
          campaign?.video_type === CAMPAIGN_VIDEO_TYPES.each
            ? t("videos")
            : t("video")
        }
        to={correctVideoUrl()}
        state={state}
      />
      <CampaignTabsItem
        active={checkClassStatus(CAMPAIGN_STEPS_VALUE.LANDING_PAGES)}
        campaignId={campaignId}
        label={isSingleLandingPage ? t("landing page") : t("landing pages")}
        to={LANDING_PAGES_URL}
        state={state}
      />
      <CampaignTabsItem
        active={checkClassStatus(CAMPAIGN_STEPS_VALUE.PUBLISH)}
        campaignId={campaignId}
        label={!campaign || campaignDraft ? t("publish") : t("publish changes")}
        to={PUBLISH_URL}
        state={state}
      />
    </nav>
  );
};

CampaignTabs.defaultProps = {
  campaign: {}
} 
CampaignTabs.propTypes = {
  campaign: PropTypes.shape(CampaignModel),
};

export default CampaignTabs;
