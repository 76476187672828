import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/logo.svg";
import "./styles.scss";

const AuthHeader = ({ buttonData, isButton }) => {
  const { t } = useTranslation();
  return (
    <div className="AuthHeader">
      <Link to="/" className="AuthHeader_top__logo">
        <img src={Logo} alt="Logo" />
      </Link>
      {isButton && <Link to={buttonData.link} className="routeBtn btn btn-outline-dark">
        {t(buttonData.text)}
      </Link>}
    </div>
  );
};

AuthHeader.defaultProps = {
  buttonData: {
    link: "/login",
    text: "Sign In",
  },
  isButton: true
};
AuthHeader.propTypes = {
  buttonData: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  isButton: PropTypes.bool
};

export default AuthHeader;
