import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { path } from "ramda";
import Icon from "../../common/icon";
import ModalContainer from "../../../containers/modal";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS,
  ICONS_NAMES,
  CAMPAIGN_STEPS_VALUE,
} from "../../../constants/common";
import { CampaignModel } from "../../../utils/helpers/defPropTypes";
import { useModal } from "../../../utils/helpers/useModal";


const CampaignListItem = ({
  campaign,
  onDelete,
  onChangeStatus,
  className,
  onDuplicate
}) => {
  const { t } = useTranslation();
  const {toggleModal, isOpenModal} = useModal();
  const [modalContent, setModalContent] = useState("Are you sure?");
  const [submitProps, setSubmitProps] = useState({})
  const userToken = useSelector(({ user: { token } }) => token);
  const downloadCSVFileUrl = `${
    process.env.REACT_APP_API_URL
  }/api/campaigns/${path(["id"], campaign)}/csv-urls?token=${userToken}`;
  


  const changeModalContent = (data) => {
    let modalText;
    switch (data.type) {
      case "delete":
        modalText = t(
          "Are you sure you want to delete {{title}}? \nYou will lose all your data.",
          { title: campaign.campaign_title }
        );
        break;
      case "changeStatus":
        modalText = t(
          "Are you sure you want to {{status}} your campaign {{title}}?",
          {
            status: data.checked ? "activate" : "deactivate",
            title: campaign.campaign_title,
          }
        );
        break;
      default:
        modalText = t("Are you sure?");
        break;
    }
    setModalContent(modalText);
  };

  const deleteHandler = () => {
    toggleModal();
    changeModalContent({ type: "delete" });
    setSubmitProps({ type: "delete" })
    
  };

  const changeStatusHandler = ({ target: { checked } }) => {
    toggleModal();
    changeModalContent({ type: "changeStatus", checked });
    setSubmitProps({ type: "changeStatus", checked })
  };

  const getStatusClassName = () => {
    switch (campaign.status) {
      case CAMPAIGN_STATUSES.active.id:
        return "Active";
      case CAMPAIGN_STATUSES.inactive.id:
        return "Inactive";
      default:
        return "Draft";
    }
  };

  const submitModalHandler = (data) => {
    if (data.type === "changeStatus") {
      if (onChangeStatus && typeof onChangeStatus === "function") {
        onChangeStatus(campaign, data.checked);
      }
    } else if (
      data.type === "delete" &&
      onDelete &&
      typeof onDelete === "function"
    ) {
      onDelete(campaign);
    }

    toggleModal();
  };

  return (
    <>
      <li className={`CampaignListItem ${className} ${getStatusClassName()}`}>
        {campaign.status !== CAMPAIGN_STATUSES.draft.id ? (<div className="CampaignListItem_wrap">
          <p className="CampaignListItem_title">{campaign.campaign_title}</p>
          <p className="CampaignListItem_date">Created: {campaign.created_at.slice(0,10)}</p>
        </div>) : (
          <p className="CampaignListItem_title">{campaign.campaign_title}</p>
        )}
        
        {campaign.status !== CAMPAIGN_STATUSES.draft.id && (
          <>
            <a
              className="CampaignListItem_csv"
              target="_blank"
              rel="noreferrer"
              href={downloadCSVFileUrl}
            >
              {t("Download CSV with URLs")}
            </a>
            <label className="CampaignListItem_toggle">
              <input
                type="checkbox"
                onChange={changeStatusHandler}
                checked={campaign.status === CAMPAIGN_STATUSES.active.id}
              />
              <span className="switch" />
            </label>
          </>
        )}
        <div className="CampaignListItem_action">
          {campaign.status !== CAMPAIGN_STATUSES.draft.id && 
            <button onClick={() => onDuplicate(campaign)} className="m-r-25">
              <Icon name={ICONS_NAMES.COPY} className="m-r-5"/>
              <span>{t("Duplicate")}</span>
            </button>
          }
          <NavLink
            to={{
              pathname: `/campaigns/${campaign.id}/${
                CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.LEADS]
              }`,
              state: { isEdit: true },
            }}
            className="m-r-25"
          >
            <Icon
              name={ICONS_NAMES.EDIT}
              className="m-r-5"
              size={20}
              color="#393f4d"
            />
            <span>{t("Edit")}</span>
          </NavLink>
          <button onClick={deleteHandler}>
            <Icon name={ICONS_NAMES.CROSS_CIRCLE} size={26} color="#393f4d" />
            <span>{t("Delete")}</span>
          </button>
        </div>
      </li>

      <ModalContainer
        isConfirm
        isOpen={isOpenModal}
        closeModal={toggleModal}
        onSubmit={() => submitModalHandler(submitProps)}
        btnText={{ yes: t("Yes"), no: t("Not now") }}
      >
        <h3 className="modal_confirmation__body__title">{modalContent}</h3>
      </ModalContainer>
    </>
  );
};

CampaignListItem.defaultProps = {
  onDelete: null,
  onChangeStatus: null,
  className: "",
  onDuplicate: null
};
CampaignListItem.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
  onDelete: PropTypes.func,
  onChangeStatus: PropTypes.func,
  className: PropTypes.string,
  onDuplicate: PropTypes.func,
};

export default CampaignListItem;
