import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../common/loader"
import VideoEachTypeScreen from "./videoEachTypeScreen"
import VideoGenericTypeScreen from "./videoGenericTypeScreen"
import VideoSelectTypeScreen from "./videoSelectTypeScreen"

import { CAMPAIGN_VIDEO_TYPES } from "../../../constants/common";

const VideosTab = () => {
  const { campaign, leads } = useSelector(({ campaigns }) => ({
    campaign: campaigns.current,
    leads: campaigns.leads,
  }));
  if (!campaign) return <Loader />;

  switch (campaign.video_type) {
    case CAMPAIGN_VIDEO_TYPES.each:
      return <VideoEachTypeScreen />;
    case CAMPAIGN_VIDEO_TYPES.generic:
      return <VideoGenericTypeScreen campaign={campaign} />;
    default:
      return <VideoSelectTypeScreen campaign={campaign} leads={leads}/>;
  }
};

export default VideosTab;
