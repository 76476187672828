import React from "react";
import { useSelector } from "react-redux";

const styles = {
  position: "fixed",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  bgColor: "#fff",
  zIndex: 99999,
};

const BlockSiteIsLoading = () => {
  const loading = useSelector(({ ui: { requestLoading } }) => requestLoading);

  if (loading) return <div style={styles} />;

  return null;
};

export default BlockSiteIsLoading;
