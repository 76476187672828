import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTES } from "../constants/routes";
import { START_ROUTE_LINK, REDIRECT_CAMPAIGN_FAIL } from "../constants/common";
import { Page404 } from "../pages";

const MainRoutes = () => {
  const redirectTo = useSelector(({ ui }) => ui.redirectTo);
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Switch>
        <Route
          exact
          component={() => (
            <Page404 text="It seems that the user has deleted this campaign." />
          )}
          path={REDIRECT_CAMPAIGN_FAIL}
        />

        {redirectTo && <Redirect to={redirectTo} />}

        {ROUTES.MAIN.map((route) => (
          <Route
            exact={route.exact}
            key={`router-${route.uid}`}
            component={route.component}
            path={route.link}
          />
        ))}
        <Redirect exact to={START_ROUTE_LINK} from="/" />
        <Route component={Page404} />
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
