import types from "./types";
import apiUrls from "../../constants/apiUrls";


const getProfileRequest = () => ({
  type: types.GET_PROFILE,
  payload: {
    request: { url: apiUrls.profile, method: "GET" },
  },
});
const updateProfileRequest = (data) => ({
  type: types.UPDATE_PROFILE,
  payload: {
    request: { url: apiUrls.profile, method: "PUT", data },
  },
});


const updateProfileCompanyRequest = (data) => ({
  type: types.UPDATE_PROFILE_COMPANY,
  payload: {
    request: { url: apiUrls.updateCompanyProfile, method: "PUT", data },
  },
});

const updateProfileUserRequest = (data) => ({
  type: types.UPDATE_PROFILE_USER,
  payload: {
    request: { url: apiUrls.updateUserProfile, method: "PUT", data },
  },
});

export {
  getProfileRequest,
  updateProfileCompanyRequest,
  updateProfileUserRequest,
  updateProfileRequest
};
