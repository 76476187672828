import React from "react";
import { useTranslation } from "react-i18next";
import CSV from "../../../../assets/img/CSV.png";
import Example from "../../../../assets/img/Example.png";
import "./style.scss";
import Icon from "../../../common/icon";
import { CSV_EXAMPLE_NAME, ICONS_NAMES } from "../../../../constants/common";

const InfoCSV = () => {
  const { t } = useTranslation();
  return (
    <div className="info_csv">
      <ul className="info_csv__points">
        <li>
          {t(
            "The variables of the column header need to match the variables of the landing pages, if you wish to personalize them."
          )}
        </li>
        <li>
          {t(
            "Start with a simple spreadsheet and save as .CSV, or export from your CRM."
          )}
        </li>
      </ul>
      <div className="info_csv__example">
        <h5 className="info_csv__example__title">
          {t("CSV file format example")}
        </h5>
        <a
          href={`/${CSV_EXAMPLE_NAME}`}
          className="info_csv__example__download m-b-10"
          download
        >
          <Icon size={20} color="#393f4d" name={ICONS_NAMES.DOWNLOAD} />{" "}
          <span>{t("Download template")}</span>
        </a>
      </div>
      <div className="info_csv__img_wrapper">
        <img src={Example} alt={t("Example of CSV")} />
        <img src={CSV} alt={t("CSV file")} />
      </div>
    </div>
  );
};

export default InfoCSV;
