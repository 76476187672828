import types from "./types";
import apiUrls from "../../constants/apiUrls";


const setMemberPasswordRequest = (data) => ({
  type: types.SET_MEMBER_PASSWORD,
  payload: {
    request: { 
      url: apiUrls.setMemberPassword, 
      method: "POST", 
      data 
    },
  },
});
const getMembersRequest = () => ({
  type: types.GET_MEMBERS,
  payload: {
    request: {
      method: "GET",
      url: apiUrls.members,
    },
  },
});
const createMemberRequest = (data) => ({
  type: types.CREATE_MEMBER,
  payload: {
    request: { 
      url: apiUrls.inviteMember, 
      method: "POST", 
      data 
    },
  },
});

const createMemberRequestSuccess = ({ getState, response }) => {
  const { teamMembers: { members } } = getState();
  return {
    members: [...members, response.data.model]
  };
};

const deleteMemberRequest = (id) => ({
  type: types.DELETE_MEMBER,
  payload: {
    request: {
      method: "DELETE",
      url: `${apiUrls.members}/${id}`,
    },
  },
});
const deleteMember = (id) => async (dispatch) => {
  await dispatch(deleteMemberRequest(id))
  await dispatch(getMembersRequest());
};


const updateMemberRequest = (id, data) => ({
  type: types.UPDATE_MEMBER,
  payload: {
    request: {
      method: "PUT",
      url: `${apiUrls.members}/${id}`,
      data
    },
  },
});

const updateMemberRequestSuccess = ({ getState, response }) => {
  const { teamMembers: { members } } = getState();
  const { id } = response.data.model
  const idx = members.findIndex((el) => el.id === id);
  return {
    members: [...members.slice(0, idx), response.data.model, ...members.slice(idx + 1)]
  };
};

const resendInviteRequest = (id) => ({
  type: types.RESEND_INVITE,
  payload: {
    request: {
      method: "POST",
      url: `${apiUrls.resendInvite}`,
      data: {id}
    },
  },
});

export {
  setMemberPasswordRequest,
  createMemberRequest,
  getMembersRequest,
  deleteMemberRequest,
  updateMemberRequest,
  resendInviteRequest,
  deleteMember,
  createMemberRequestSuccess,
  updateMemberRequestSuccess
};
