import React from "react";
import { Switch, Route } from "react-router-dom";
import { isAuthenticated, noAuthenticated } from "../utils/helpers/authHelper";
import { ROUTES } from "../constants/routes";
import Main from "../containers/main";
import Auth from "../pages/auth";

const AppRoutes = () => (
  <Switch>
    <Route component={noAuthenticated(Auth.SignIn)} path={ROUTES.LOGIN} />
    <Route component={noAuthenticated(Auth.SignUp)} path={ROUTES.REGISTER} />
    <Route
      component={noAuthenticated(Auth.ForgotPassword)}
      path={ROUTES.FORGOT_PASSWORD}
    />
    <Route
      component={noAuthenticated(Auth.ResetPassword)}
      path={ROUTES.RESET_PASSWORD}
    />
    <Route
      component={Auth.NewMemberPassword}
      path={ROUTES.NEW_MEMBER_PASSWORD}
    />
    <Route component={isAuthenticated(Main)} path="/" />
  </Switch>
);

export default AppRoutes;
