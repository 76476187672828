import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { deleteFile, uploadFile } from "../../../../store/files/operation";
import {
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
  UPLOAD_FILES_TYPES,
} from "../../../../constants/common";
import { campaignsOperation } from "../../../../store/campaigns";
import FileUpload from "../../../common/fileUpload";
import InfoCSV from "../InfoCSV";
import Loader from "../../../common/loader";
import "./style.scss";

const AddLeads = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const csv = useSelector((store) => store.files.csv);
  const loading = useSelector(({ ui: { requestLoading } }) => requestLoading);

  const onChangeFile = ({ target: { files } }) => {
    if (files) {
      const [file] = files;
      dispatch(uploadFile(file, UPLOAD_FILES_TYPES.CSV));
    }
  };

  useEffect(() => {
    if (csv && csv.id) {
      if (Number(campaignId)) {
        dispatch(
          campaignsOperation.updateCampaignRequest({
            id: +campaignId,
            csv_file_id: csv.id,
            step: CAMPAIGN_STEPS_VALUE.LEADS,
            video_type: CAMPAIGN_VIDEO_TYPES.none,
          })
        );
      } else {
        dispatch(
          campaignsOperation.createCampaignRequest({
            csv_file_id: csv.id,
            step: CAMPAIGN_STEPS_VALUE.LEADS,
          })
        );
      }
    }
    return () => dispatch(deleteFile(UPLOAD_FILES_TYPES.CSV));
  }, [
    csv,
    campaignsOperation.createCampaignRequest,
    campaignsOperation.updateCampaignRequest,
    deleteFile,
  ]);

  return (
    <div className="add_variables_step">
      {loading ? (
        <Loader />
      ) : (
        <>
          <h1 className="add-leads-title">
            {t("Start your campaign by adding leads")}
          </h1>
          <div className="container">
            <div className="ratio">
              <FileUpload
                withDeleteButton={false}
                type="text"
                accept=".csv"
                className="upload_csv"
                infoText={t(
                  "only .csv format, max. 10 columns (10 variables), max. 100 lines"
                )}
                btnText={t("Click to upload CSV file")}
                onChange={onChangeFile}
              />
            </div>
            <InfoCSV />
          </div>
        </>
      )}
    </div>
  );
};

export default AddLeads;
