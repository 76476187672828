import React from "react";
import { Form } from "formik";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AuthFooter, AuthHeader, Input, Loader } from "../../../components";
import { AuthForm } from "../../../containers";
import { userOperation } from "../../../store/user";
import { ROUTES } from "../../../constants/routes";
import { signInValidator } from "./signInValidator";
import "../style.scss";

const SingIn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(({ ui }) => ui.requestLoading);
  const initialValues = { email: "", password: "" };

  const loginHandler = (values) => {
    dispatch(userOperation.login(values));
  };

  return (
    <div className="Auth">
      <AuthHeader buttonData={{ link: ROUTES.REGISTER, text: t("Sign Up") }} />

      <div className="Auth_content">
        <AuthForm
          title={t("Sign In")}
          initialValues={initialValues}
          validate={signInValidator}
          onSubmit={loginHandler}
        >
          {({ errors, touched }) => (
            <Form>
              <Input
                name="email"
                type="email"
                error={errors.email}
                touched={touched.email}
                placeholder={t("E-mail address")}
              />
              <Input
                className="m-b-0"
                name="password"
                type="password"
                error={errors.password}
                touched={touched.password}
                placeholder={t("Password")}
              />
              <Link
                className="Auth_content__link text-left m-b-5"
                to={ROUTES.FORGOT_PASSWORD}
              >
                {t("Forgot password?")}
              </Link>
              <button
                className="btn btn-block m-t-10"
                type="submit"
                disabled={loading}
              >
                {loading ? <Loader secondary /> : t("Sign in")}
              </button>

              <Link
                className="Auth_content__link text-center m-t-5"
                to={ROUTES.REGISTER}
              >
                {t("You haven’t registered yet?")}
              </Link>
            </Form>
          )}
        </AuthForm>
      </div>

      <AuthFooter />
    </div>
  );
};

export default SingIn;
