import axios from 'axios'
import Storage from "./Storage";
import { API_METHODS } from "../../constants/common";


const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
})

instance.interceptors.request.use((config) => {
    const token = Storage.get(process.env.REACT_APP_TOKEN_KEY);
    if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    return config;
});

const apiHelper = ({requestUrl, method, data, config})  => {
    const apiMethodsMap = {
        [API_METHODS.GET]: () => instance.get(requestUrl, config),
        [API_METHODS.POST]: () => instance.post(requestUrl, data, config),
        [API_METHODS.PUT]: () => instance.put(requestUrl, data, config),
        [API_METHODS.DELETE]: () => instance.delete(requestUrl, config),
    }
    return apiMethodsMap[method] || console.warn("Unknown api method");
}

export default apiHelper