import { handleActions } from "redux-actions";
import { mergeIn } from "../../utils/helpers/stateHelpers";
import types from "./types";
import { userTypes } from "../user";

const initialState = {
  data: [],
  current: null,
  leads: null,
  leadsEntities: {},
  campaignsEntities: {
    draft: {},
    active: {},
    inactive: {},
  },
  searchResults: null,
  draft: [],
  active: [],
  inactive: [],
  status: null
};

const reducer = handleActions(
  {
    [userTypes.LOGOUT]: () => initialState,
    [types.GET_CAMPAIGNS_SUCCESS]: mergeIn(
      ({
        payload: {
          config,
          data: { data },
        },
      }) => ({ [config?.campaigns_status]: data, data})
    ),
    [types.GET_CAMPAIGN_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { model },
        },
      }) => ({
        current: model,
      })
    ),
    [types.CLEAR_CURRENT_CAMPAIGN]: mergeIn(() => ({ current: null })),
    [types.CLEAR_CAMPAIGN_LEADS]: mergeIn(() => ({ leads: null })),
    [types.CLEAR_CAMPAIGN_ENTITIES]: mergeIn(() => ({ campaignsEntities: {} })),
    [types.SET_SEARCH_RESULTS]: mergeIn(({ payload:  data }) => ({ searchResults: data })),
    [types.GET_CAMPAIGN_LEADS_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { data },
        },
      }) => ({
        leads: data,
      })
    ),
    [types.GET_CAMPAIGNS_LEADS_REQUEST_SUCCESS]: mergeIn(
      (
        {
          payload: {
             data,
          },
        },
        { leadsEntities }
      ) => {
        let campaignId;
        if (data.count) {
          const { data: leadList } = data
          const [lead] = leadList;
          campaignId = lead.campaign_id;
        }
        return {
          leadsEntities: { ...leadsEntities, [campaignId]: data },
        };
      }
    ),
    [types.SET_CAMPAIGNS_BY_STATUS]: mergeIn(({ payload:{ data, status } }, { campaignsEntities }) => ({
        campaignsEntities: { ...campaignsEntities, [status]: data },
      })
    ),
    [types.SET_LEADS_BY_CAMPAIGN]: mergeIn(({ payload:{ data, campaignId } }, { leadsEntities }) => ({
      leadsEntities: { ...leadsEntities, [campaignId]: data },
      })
    ),
    [types.CREATE_CAMPAIGN_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { model },
        },
      }) => ({
        current: model,
      })
    ),
    [types.UPDATE_CAMPAIGN_SUCCESS]: mergeIn(
      ({ payload: { campaign, campaigns, current } }) => ({
        active: campaign?.active,
        inactive: campaign?.inactive,
        current,
        data: campaigns,
      })
    ),
    [types.DELETE_CAMPAIGN_SUCCESS]: mergeIn(({ payload: {active, inactive, draft} }) => ({
      active, 
      inactive, 
      draft
    })),
    [types.UPDATE_LEAD_SUCCESS]: mergeIn(({ payload }) => ({
      leads: payload,
    })),
    [types.DELETE_LEAD_SUCCESS]: mergeIn(
      (
        {
          payload: {
            data: { model },
          },
        },
        { leads }
      ) => ({
        leads: leads.filter((lead) => lead.id !== model.id),
      })
    ),
    [types.CREATE_CAMPAIGN_DUPLICATE_SUCCESS]: mergeIn(
      ({
        payload: {
          draft,
        }
      }) => ({
        draft,
      })
    ),
    [types.REFRESH_CAMPAIGN_URL_SUCCESS]: mergeIn(
      ({ payload: { status } }) => ({
        status
      })
    ),
    [types.CLEAR_REFRESH_URL_STATUS]: mergeIn(() => ({ status: null })),

  },
  initialState
);

export default reducer;
