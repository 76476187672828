import React from "react";
import { Icon } from "../../components";
import "./style.scss";
import { ICONS_NAMES } from "../../constants/common";

const SupportPage = () => {
  return (
    <div className="SupportPage">
      <h1 className="pageTitle m-b-30">
        <Icon name={ICONS_NAMES.SUPPORT} className="m-r-40" />
        <span>Support</span>
      </h1>
      <p className="pageSubTitle m-b-30">
        To reach us you have the following options
      </p>
      <div className="m-b-15 d-flex align-items-center">
        <Icon name={ICONS_NAMES.PHONE} className="m-r-25" />
        <a className="text-black" href="tel:+41764044779">
            +41 76 404 47 79
        </a>
      </div>
      <div className="d-flex align-items-center">
        <Icon name={ICONS_NAMES.EMAIL} className="m-r-25" />
        <a
          className="text-black"
          rel="noreferrer"
          href="mailto:support@parruu.com"
        >
          support@parruu.com
        </a>
      </div>
    </div>
  );
};

SupportPage.propTypes = {};

export default SupportPage;
