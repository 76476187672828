import { path } from "ramda";
import types from "./types";
import apiUrls from "../../constants/apiUrls";
import { uiAction, uiTypes } from "../ui";
import { filesTypes } from "../files";
import { getResErrMessage } from "../../utils/helpers/responseHelpers";
import {
  API_METHODS, CAMPAIGN_STATUS,
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS_VALUE,
  UPLOAD_FILES_TYPES,
} from "../../constants/common";
import apiHelper from "../../utils/helpers/apiHelper";
import { setCampaignsByStatus, setLeadsByCampaign, setSearchResults } from "./action";

const VIDEO_UPLOAD_LOADING_PART = 80;
const CUSTOM_PROGRESS_VALUE = 99;
const TOTAL_PROGRESS = 100;


// General func for campaign operation
const getCompletedPercent = ({loaded, total}) => Math.round( (loaded * TOTAL_PROGRESS) / total)
const createProgressConfig = ({progressEvent, dispatch, progressLimit = TOTAL_PROGRESS, customLoading = 0}) => {
  const percentCompleted = getCompletedPercent(progressEvent);
  if (percentCompleted <= progressLimit) {
    dispatch({
      type: filesTypes.SET_UPLOAD_PROGRESS,
      payload: customLoading || percentCompleted,
    });
  }
}
const createFormData = (file, attr) => {
  const data = new FormData();
  data.append("file", file);
  data.append("attr", attr);
  return data
}

const getCampaignsRequest = ({campaignsStatus, params}) => ({
    type: types.GET_CAMPAIGNS,
    payload: {
      request: {
        method: "GET",
        url: apiUrls.campaign,
        campaigns_status: campaignsStatus,
        params: { ...params },
      },
      
    }
});

const getCampaignRequest = (id) => ({
  type: types.GET_CAMPAIGN,
  payload: {
    request: {
      method: "GET",
      url: `${apiUrls.campaign}/${id}`,
    },
  },
});

const deleteCampaignRequest = (id) => ({
  type: types.DELETE_CAMPAIGN,
  payload: {
    request: {
      method: "DELETE",
      url: `${apiUrls.campaign}/${id}`,
    },
  },
});

const deleteCampaignRequestSuccess = ({ getState, response }) => {
  const { campaigns } = getState();
  const statusId = path(["data", "model", "status"], response)
  const campaignId = path(["data", "model", "id"], response)
  const { active, inactive, draft } = campaigns;
  let newArr = [];
  let obj = {};
  
  if(statusId === CAMPAIGN_STATUSES.draft.id) {
    newArr = draft.filter((item) => item.id !== campaignId);
    obj = {active, inactive, draft: newArr};
  } else if (statusId === CAMPAIGN_STATUSES.active.id) {
    newArr = active.filter((item) => item.id !== campaignId);
    obj = {active: newArr, inactive, draft};
  } else {
    newArr = inactive.filter((item) => item.id !== campaignId)
    obj = {inactive: newArr, active, draft};
  }
  return obj
};

const updateCampaignRequest = (campaign) => ({
  type: types.UPDATE_CAMPAIGN,
  payload: {
    request: {
      method: "PUT",
      url: `${apiUrls.campaign}/${campaign.id}`,
      data: campaign,
    },
  },
});

const updateCampaignSortingRequest = (campaign, sortBy, order) => ({
  type: types.UPDATE_CAMPAIGN,
  payload: {
    request: {
      method: "PUT",
      url: `${apiUrls.campaign}/${campaign.id}/sort-params`,
      data: { sorting_attribute: sortBy, sorting_order: order },
    },
  },
});

const updateCampaignRequestSuccess = ({ getState, response, action }) => {
  const { campaigns } = getState();
  const {active, inactive } = campaigns;
  const statusId = path(["data", "model", "status"], response)
  const campaignId = path(["data", "model", "id"], response)

  const filterCampaign = () => {
    let inactiveArr = []
    let activeArr = []
    if(statusId === CAMPAIGN_STATUSES.active.id) {
      inactiveArr = inactive?.filter((item) => item.id !== campaignId)
      activeArr = [...active,  {...response.data.model, index: path(["payload", "request", "data", "index"], action)}]
    }
    if (statusId === CAMPAIGN_STATUSES.inactive.id) {
      activeArr = active?.filter((item)=>item.id !== campaignId)
      inactiveArr = [...inactive,  { 
        ...response.data.model,
        index: path(["payload", "request", "data", "index"], action)}]
    }
      return {inactive: inactiveArr, active: activeArr}
  }

  return {
    campaign: filterCampaign(),
    campaigns: campaigns.data.map((item) => {
      if (item.id === path(["data", "model", "id"], response)) {
        return {
          ...response.data.model,
          index: path(["payload", "request", "data", "index"], action),
        };
      }
      return item;
    }),
    current: {
      ...response.data.model,
      index: path(["payload", "request", "data", "index"], action),
    },
  };
};

const createCampaignVideoRequest = (video, campaign) => async (
  dispatch,
  getState
) => {
  const { campaigns } = getState();
  const {
    id: campaignId,
    video_id: campaignVideoId,
    index: campaignIndex
  } = campaign

  const data = createFormData(video, UPLOAD_FILES_TYPES.VIDEO);

  dispatch(uiAction.requestLoading(true));
  try {

    // Upload new video file
    const {
      data: { id: videoId },
    } = await apiHelper({
      requestUrl: apiUrls.files,
      method: API_METHODS.POST,
      data,
      config: {
      onUploadProgress: (progressEvent) => {
        createProgressConfig({
          progressEvent,
          dispatch,
          progressLimit: VIDEO_UPLOAD_LOADING_PART
        })
      }
    }
  })()

    // Delete previous campaign video if it exist
    if (campaign.video_id && videoId) {
      await apiHelper({
            requestUrl: `${apiUrls.campaign}/${campaignId}/videos/${campaignVideoId}`,
            method: API_METHODS.DELETE
          })
      ()
    }

    // Add new video to campaign
    const {
      data: { model },
    } = await apiHelper({
      requestUrl: `${apiUrls.campaign}/${campaignId}`,
      method: API_METHODS.PUT,
      data: {
          temp_video_id: videoId,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS
        },
      config: {
          onUploadProgress: (progressEvent) => {
            createProgressConfig({
              progressEvent,
              dispatch,
              customLoading: CUSTOM_PROGRESS_VALUE
            })
          }
        }
  })()

    const newCampaigns = campaigns.data.map((item) => {
      if (item.id === model.id) {
        return { ...model, index: campaignIndex };
      }
      return item;
    });


    dispatch({
      type: types.UPDATE_CAMPAIGN_SUCCESS,
      payload: {
        campaigns: newCampaigns,
        current: { ...model, index: campaignIndex },
      },
    });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: filesTypes.SET_UPLOAD_PROGRESS,
      payload: null,
    });
  } catch (e) {
    console.log("e", e)
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_CAMPAIGN_FAIL,
      payload: "Request error",
    });
  }
};

const getLeadsRequest = (id, params) => ({
  type: types.GET_CAMPAIGN_LEADS,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads`,
      method: "GET",
      params,
    },
  },
});

const getCampaignsLeadsRequest = (id, params) => ({
  type: types.GET_CAMPAIGNS_LEADS_REQUEST,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads`,
      method: "GET",
      params,
    },
  },
});

const createLeadRequest = (id) => ({
  type: types.CREATE_LEAD,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads`,
      method: "POST",
    },
  },
});

const createCampaignRequest = (data) => ({
  type: types.CREATE_CAMPAIGN,
  payload: {
    request: {
      url: apiUrls.campaign,
      method: "POST",
      data,
    },
  },
});

const deleteLeadRequest = (id, leadId) => ({
  type: types.DELETE_LEAD,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads/${leadId}`,
      method: "DELETE",
    },
  },
});

const updateLeadRequest = (id, leadId, data) => ({
  type: types.UPDATE_LEAD,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads/${leadId}`,
      method: "PUT",
      data,
    },
  },
});

const deleteVideoRequest = (id, videoId) => ({
  type: types.DELETE_VIDEO,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/videos/${videoId}`,
      method: "DELETE",
    },
  },
});

const deleteLeadVideo = (id, videoId) => async (dispatch) => {
  await dispatch(deleteVideoRequest(id, videoId));
  await dispatch(getLeadsRequest(id));
};

const deleteCampaignVideo = (id, videoId) => async (dispatch) => {
  await dispatch(deleteVideoRequest(id, videoId));
  await dispatch(getCampaignRequest(id));
};

const uploadVideoAndUpdateLead = ({ video, campaignId, lead: activeLead }) => async (dispatch, getState) => {
  const { campaigns: { leads } } = getState();
  const { id:leadId, video_id: leadVideoId } = activeLead

  const data = createFormData(video, UPLOAD_FILES_TYPES.VIDEO);
  dispatch(uiAction.requestLoading(true));
  try {
    // Upload new video file
     const {
       data: { id: videoId }
     } = await apiHelper({
       requestUrl: apiUrls.files,
       method: API_METHODS.POST,
       data,
       config: {
         onUploadProgress: (progressEvent) => {
           createProgressConfig({
             progressEvent,
             dispatch,
             progressLimit: VIDEO_UPLOAD_LOADING_PART
           })
         }
       }
     })()
    // Delete previous lead video if it exist
    if (leadVideoId && videoId) {
      await apiHelper({
          requestUrl: `${apiUrls.campaign}/${campaignId}/videos/${leadVideoId}`,
          method: API_METHODS.DELETE
      })()
    }

    // Add new video to lead
    const {
      data: { model },
    } = await apiHelper({
      requestUrl: `${apiUrls.campaign}/${campaignId}/leads/${leadId}`,
      method: API_METHODS.PUT,
      data: { temp_video_id: videoId },
      config: {
          onUploadProgress: (progressEvent) => {
            createProgressConfig({
              progressEvent,
              dispatch,
              customLoading: CUSTOM_PROGRESS_VALUE
            })
          }
        }
    })()

    const newLeadsList = leads.map((lead) => {
      if (lead.id === model.id) {
        return { ...model };
      }
      return lead;
    });

    dispatch({
      type: types.UPDATE_LEAD_SUCCESS,
      payload: newLeadsList,
    });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: filesTypes.SET_UPLOAD_PROGRESS,
      payload: null,
    });
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};

const addNewLeadsList = (csv, campaign) => async (dispatch) => {
  const data = createFormData(csv, UPLOAD_FILES_TYPES.CSV);
  dispatch(uiAction.requestLoading(true));
  try {
    const {
      data: { id: csvId },
    } = await apiHelper({
      requestUrl: `${apiUrls.files}`,
      method: API_METHODS.POST,
      data
    })()

    await dispatch(
      updateCampaignRequest({
        ...campaign,
        csv_file_id: csvId,
        step: CAMPAIGN_STEPS_VALUE.LEADS,
        status: CAMPAIGN_STATUSES.draft.id,
      })
    );
    await dispatch(getLeadsRequest(campaign.id));
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_CAMPAIGN_FAIL,
      payload: "Request error",
    });
  }
};

const addNewLead = (campaignId) => async (dispatch) => {
  await dispatch(createLeadRequest(campaignId));
  await dispatch(getLeadsRequest(campaignId));
};

const updateLeadFields = (id, leadId, data) => async (dispatch, getState) => {
  const { campaigns: { leads } } = getState();
  dispatch(uiAction.requestLoading(true));
  try {
    const {
      data: { model },
    } =  await apiHelper({
      requestUrl: `${apiUrls.campaign}/${id}/leads/${leadId}`,
      method: API_METHODS.PUT,
      data
    })()

    const newLeadsList = leads.map((lead) => {
      if (lead.id === model.id) {
        return { ...model };
      }
      return lead;
    });
    dispatch({
      type: types.UPDATE_LEAD_SUCCESS,
      payload: newLeadsList,
    });
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};

const deleteLeads = (campaignId ,ids) => async (dispatch) => {
  dispatch(uiAction.requestLoading(true));
  try {
    const { data }  =  await apiHelper({
      requestUrl: `${apiUrls.campaign}/${campaignId}/leads`,
      method: API_METHODS.DELETE,
      config: { params: { ids } }
    })()
    if (data.success){
      dispatch(getLeadsRequest(campaignId));
    }
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};

const getCampaignsByStatus = (params, slice) => async (dispatch) => {
  dispatch(uiAction.requestLoading(true));
  try {
    const { data }  =  await apiHelper({
      requestUrl: apiUrls.campaign,
      method: API_METHODS.GET,
      config: { params }
    })()
    const { status } =  params
    const { data: campaignsList } = data

    if (campaignsList.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const campaign of campaignsList){
        const { id, leads } = campaign
        const hasMore = leads.length > slice
        if (slice) leads.length = slice
        dispatch(setLeadsByCampaign({ data: { data: leads, hasMore }, campaignId: id }))
      }
    }
    dispatch(setCampaignsByStatus({data, status: CAMPAIGN_STATUS[status]}))
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};

const getCampaignLeads = (campaignId ,ids) => async (dispatch) => {
  dispatch(uiAction.requestLoading(true));
  try {
    const { data }  =  await apiHelper({
      requestUrl: `${apiUrls.campaign}/${campaignId}/leads`,
      method: API_METHODS.DELETE,
      config: { params: { ids } }
    })()
    if (data.success){
      dispatch(getLeadsRequest(campaignId));
    }
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};

const searchCampaigns = (params) => async (dispatch) => {
  dispatch(uiAction.requestLoading(true));
  try {
    const { data }  =  await apiHelper({
      requestUrl: `${apiUrls.campaign}`,
      method: API_METHODS.GET,
      config: { params }
    })()
    const { data: campaignsList } = data
    if (campaignsList.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const campaign of campaignsList){
        const { id, leads } = campaign
        dispatch(setLeadsByCampaign({ data: { data: leads }, campaignId: id }))
      }
    }
    dispatch(setSearchResults(data));
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};


const publishCampaignRequest = (data) => ({
  type: types.PUBLISH_CAMPAIGN,
  payload: {
    request: {
      method: "POST",
      url: apiUrls.publishCampaign,
      data,
    },
  },
});

const duplicateCampaignRequest = (id) => ({
  type: types.CREATE_CAMPAIGN_DUPLICATE,
  payload: {
    request: {
      method: "POST",
      url: `${apiUrls.campaign}/${id}/duplicate`,
      data: { id }
    },
  }
});

const duplicateCampaignRequestSuccess = ({ getState, response, action }) => {
  const { campaigns: {draft} } = getState();
  const newDraftArr = [...draft,  {...response.data.model, index: path(["payload", "request", "data", "index"], action)}]
  return {draft: newDraftArr}
};

const refreshCampaignUrlRequest = (id) => ({
  type: types.REFRESH_CAMPAIGN_URL,
  payload: {
    request: {
      method: "POST",
      url: `${apiUrls.campaign}/${id}/refresh-urls`,
      data: { id }
    },
  }
});

export {
  getCampaignsRequest,
  getCampaignRequest,
  getLeadsRequest,
  createCampaignRequest,
  deleteLeadRequest,
  updateLeadRequest,
  deleteCampaignRequest,
  deleteCampaignRequestSuccess,
  updateCampaignRequest,
  updateCampaignRequestSuccess,
  uploadVideoAndUpdateLead,
  updateLeadFields,
  createCampaignVideoRequest,
  addNewLeadsList,
  createLeadRequest,
  addNewLead,
  publishCampaignRequest,
  getCampaignsLeadsRequest,
  deleteVideoRequest,
  deleteLeadVideo,
  deleteCampaignVideo,
  deleteLeads,
  getCampaignLeads,
  getCampaignsByStatus,
  searchCampaigns,
  updateCampaignSortingRequest,
  duplicateCampaignRequest,
  duplicateCampaignRequestSuccess,
  refreshCampaignUrlRequest
};
