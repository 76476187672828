export default {
  SEND_VIDEO: "SEND_VIDEO",
  SEND_VIDEO_SUCCESS: "SEND_VIDEO_SUCCESS",

  SEND_CSV: "SEND_CSV",
  SEND_CSV_SUCCESS: "SEND_CSV_SUCCESS",

  SEND_LOGO: "SEND_LOGO",
  SEND_LOGO_SUCCESS: "SEND_LOGO_SUCCESS",

  SEND_AUDIO: "SEND_AUDIO",
  SEND_AUDIO_SUCCESS: "SEND_AUDIO_SUCCESS",

  CREATE_VIDEO_PAGE: "CREATE_VIDEO_PAGE",
  CREATE_VIDEO_PAGE_SUCCESS: "CREATE_VIDEO_PAGE_SUCCESS",

  CREATE_VIDEO: "CREATE_VIDEO",
  CREATE_VIDEO_SUCCESS: "CREATE_VIDEO_SUCCESS",

  GET_LANDING_PAGE_URL: "GET_LANDING_PAGE_URL",
  GET_LANDING_PAGE_URL_SUCCESS: "GET_LANDING_PAGE_URL_SUCCESS",

  GET_VIDEO_PAGE_PREVIEW: "GET_VIDEO_PAGE_PREVIEW",
  GET_VIDEO_PAGE_PREVIEW_SUCCESS: "GET_VIDEO_PAGE_PREVIEW_SUCCESS",
  SET_CREATE_PAGE_DATA: "SET_CREATE_PAGE_DATA",

  DELETE_FILE: "DELETE_FILE",

  SET_VIDEO_SCRIPT: "SET_VIDEO_SCRIPT",

  SET_LOADING: "SET_LOADING",
  SET_LOADING_SUCCESS: "SET_LOADING_SUCCESS",
  SET_UPLOAD_PROGRESS: "SET_UPLOAD_PROGRESS",
  DOWNLOAD_DATA: "DOWNLOAD_DATA",

  SET_TRAINING_RECORD: "SET_TRAINING_RECORD",
};
