import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "../../../common/icon";
import { useOutsideRef } from "../../../../utils/helpers/common";
import "./style.scss";
import { ICONS_NAMES } from "../../../../constants/common";

const ICON_SIZE = 25;

const NavDropdown = ({ logout }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const wrapperRef = useRef();
  const menus = useSelector(({ ui }) => ui.menus.profile);
  const [isOpen, setIsOpen] = useState(false);

  const closeMenuHandler = () => {
    setIsOpen((prev) => {
      if (prev) {
        return false;
      }
      return false;
    });
  };

  const toggleMenuHandler = () => setIsOpen(!isOpen);

  useOutsideRef(wrapperRef, closeMenuHandler);

  useEffect(() => {
    closeMenuHandler();
  }, [pathname]);

  return (
    <div className="NavDropdown" ref={wrapperRef}>
      <button className="NavDropdown_btn btn-pure" onClick={toggleMenuHandler}>
        <Icon name={ICONS_NAMES.MORE} color="#393f4d" />
      </button>
      <ul className={`NavDropdown_wrap ${isOpen && "active"}`}>
        {menus &&
          menus.length &&
          menus.map((menu) => {
            if (menu.url === "/logout") {
              return (
                <li
                  key={`rightMenu${menu.title.trim()}`}
                  className={`NavDropdown_wrap__item logout-item ${
                    menu.highlight && "disabled"
                  }`}
                >
                  <button className="logout-btn" onClick={logout}>
                    <Icon
                      size={ICON_SIZE}
                      color={menu.highlight ? "#888f9e" : "#1f86ff"}
                      name={menu.block_type}
                    />
                    <p className="logout-btn_text">{t(menu.title)}</p>
                  </button>
                </li>
              );
            }

            if (menu.highlight) {
              return (
                <li
                  key={`rightMenu${menu.title.trim()}`}
                  className="NavDropdown_wrap__item disabled"
                >
                  <div className="notActive">
                    <Icon
                      className="icon"
                      size={ICON_SIZE}
                      color="#888f9e"
                      name={menu.block_type}
                    />
                    <p className="text">{t(menu.title)}</p>
                    <span className="comingSoon">Coming soon</span>
                  </div>
                </li>
              );
            }

            return (
              <li
                key={`rightMenu${menu.title.trim()}`}
                className={`NavDropdown_wrap__item  ${
                  menu.url === pathname && "NavDropdown_wrap__item__active"
                }`}
              >
                <Link to={menu.url}>
                  <Icon
                    className="icon"
                    size={ICON_SIZE}
                    name={menu.block_type}
                  />
                  <p className="text">{t(menu.title)}</p>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

NavDropdown.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default NavDropdown;
