import React from "react";
import LeadsAdded from './leadsAdded'
import AddLeads from "./addLeads";
import { useCampaignAndLeads } from "../../../utils/helpers/campaignHooks";

const LeadsTab = () => {
  const { campaign, leads } = useCampaignAndLeads();

  return (
    <div className="add_variables_step">
      {campaign && leads && leads.length ? (
        <LeadsAdded leads={leads} campaign={campaign} />
      ) : (
        <AddLeads />
      )}
    </div>
  );
};

export default LeadsTab;
