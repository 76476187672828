import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import { campaignsOperation } from "../../../store/campaigns";
import Icon from '../../common/icon'
import Loader from '../../common/loader'

import { debounce } from "../../../utils/helpers/common";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS_VALUE,
  ICONS_NAMES,
} from "../../../constants/common";
import DomainInfoSettings from './domainInfo';
import { domainSchema } from './validationSchema'
import ModalContainer from "../../../containers/modal"
import { useModal } from "../../../utils/helpers/useModal";
import "./style.scss";

const PublishTab = () => {

  // HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { campaign, userToken, loading } = useSelector(
    ({ campaigns: { current }, user: { token }, ui: { requestLoading } }) => ({
      campaign: current,
      userToken: token,
      loading: requestLoading,
    })
  );
  const {toggleModal, isOpenModal} = useModal();

  // CONST
  const downloadCSVFileUrl = `${
    process.env.REACT_APP_API_URL
  }/api/campaigns/${path(["id"], campaign)}/csv-urls?token=${userToken}`;
  const previewUrl = `${process.env.REACT_APP_API_URL}/preview-campaign/${campaign?.preview_code}`;
  const campaignDraft = campaign && campaign.status === CAMPAIGN_STATUSES.draft.id;


  // FUNCTIONS

  const publishHandler = useCallback((values) => {
    dispatch(
      campaignsOperation.updateCampaignRequest({
        id: campaign.id,
        step: CAMPAIGN_STEPS_VALUE.PUBLISH + 1,
        status: CAMPAIGN_STATUSES.active.id,
        ...values
      })
    );
    if (state) state.isEdit = false;
  }, [campaign]);

  const formik = useFormik({
    initialValues: {
      custom_url: campaign?.custom_url || "",
      custom_meta_title: campaign?.custom_meta_title || "",
      custom_meta_description: campaign?.custom_meta_description || "",
      custom_preview_url: campaign?.custom_preview_url || "",
      custom_video_url: campaign?.custom_video_url || ""
    },
    validationSchema: domainSchema,
    enableReinitialize: true,
    onSubmit: publishHandler
  });

  const updateTitleHandler = debounce(1000, ({ target: { value } }) =>
    dispatch(
      campaignsOperation.updateCampaignRequest({
        id: campaign.id,
        button_url: campaign.button_url,
        logo_file_id: campaign.logo_file_id,
        button_title: campaign.button_title,
        content: campaign.content,
        step: campaign.step,
        status: campaign.status,
        campaign_title: value,
      })
    )
  )();

  if (!campaign) return <Loader />;

  if (
    campaign.status === CAMPAIGN_STATUSES.active.id &&
    campaign.step > CAMPAIGN_STEPS_VALUE.PUBLISH &&
    !state?.isEdit
  ) {
    return (
      <div className="PublishTab PublishTab_published">
        <h5 className="pageSubTitle text-success m-b-25 d-flex align-items-center">
          <Icon name={ICONS_NAMES.TICK} size={40} color="#7ed321" />
          <span>{t("Your campaign has been published")}</span>
          {loading && <Loader secondary />}
        </h5>
        <div className="PublishTab_grid">
          <div className="PublishTab_left">
            <div className="PublishTab_left__top m-b-75">
              <a
                className="btn btn-blue btn-block m-b-15"
                href={downloadCSVFileUrl}
              >
                {t("Download CSV with URLs")}
              </a>
              <a
                className="btn btn-outline-blue btn-block"
                target="_blank"
                rel="noreferrer"
                href={previewUrl}
              >
                {t("Preview a page")}
              </a>
            </div>

            <div className="PublishTab_left__bottom">
              <p className="pageSubTitle m-b-10">Further options</p>
              <div className="m-b-10 position-relative">
                <input
                  maxLength={150}
                  className="PublishTab_input"
                  name="title"
                  type="text"
                  defaultValue={campaign?.campaign_title}
                  placeholder={t("Campaign name")}
                  onChange={updateTitleHandler}
                />
              </div>
              <Link
                className="btn btn-outline-dark btn-block"
                to="/campaigns/leads"
              >
                {t("Create a new campaign")}
              </Link>
            </div>
          </div>
          <div className="PublishTab_right">
            <p>
              {t(
                "You can download a CSV to add the published pages to your communication."
              )}
            </p>
            <p>{t("The CSV contains")}:</p>
            <ul>
              <li>{t("Your original data")}</li>
              <li>{t("Link to your videos only")}</li>
              <li>{t("Link to your landing pages incl. videos")}</li>
              <li>{t("Link")}</li>
              <li>
                {t(
                  "Link to your landing pages with video thumbnail and play button as as GIF"
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <DomainInfoSettings campaign={campaign} {...formik}/>
      <div className="buttonsWrap">
            <div className="PublishTab_left">
              <div className="PublishTab_left__top m-b-75">
                <button className="btn btn-block m-b-15" onClick={formik.handleSubmit}>
                  {campaignDraft ? t("Publish your campaign now") : t("Publish your changes now")}
                </button>
                <a
                  className="btn btn-block btn-outline-blue"
                  target="_blank"
                  rel="noreferrer"
                  href={previewUrl}
                >
                  {t("Preview a page")}
                </a>
                <div 
                  className="infoBlock" 
                  role="presentation" 
                  onClick={toggleModal}>
                  <span>!</span>
                  <p>{t("More info on how your campaign is published")}</p>
                </div>

              </div>
          </div>
      </div>
      <ModalContainer
        isOpen={isOpenModal}
        closeModal={toggleModal}
        isInfo
        className="InfoModal"
        btnText={{ ok: "Ok, got it!"}}
        >
          <div className="modal-wrap">
              <h4>{t("How is my campaign published?")}</h4>
              <div className="modal-text-wrap">
                <p>
                  {t(`Each video will be published on its own personalized 
                  landing page, according to your leads variable.`)}
                </p>
                <p>
                  {t(`If you have recorded/uploaded a video for each lead, the personalized 
                  video will appear on the corresponding landing page of that lead.`)}
                </p>
                <p>
                  {t(`You can download a CSV file with the original data and links to videos 
                  and pages after your campaign has been published. 
                  This data you can use or add to any of your email, social or CRM
                  communication.`)}
                </p>
              </div>
          </div>
      </ModalContainer>

    </>
  )
};

PublishTab.defaultProps = {
  handleSubmit: null
}

PublishTab.propTypes = {
  handleSubmit: PropTypes.func
};

export default PublishTab;
