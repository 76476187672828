import { handleActions } from "redux-actions";
import types from "./types";
import { mergeIn } from "../../utils/helpers/stateHelpers";

const initialState = {
  countries: [],
};

const reducer = handleActions(
  {
    [types.GET_COUNTRIES_SUCCESS]: mergeIn(
      ({
        payload: {
          data,
        },
      }) => ({ countries: data })
    ),
    [types.GET_COUNTRIES_FAIL]: () => ({ countries: [] }),
  },
  initialState
);

export default reducer;
