export default {

  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",


  UPDATE_PROFILE_COMPANY: "UPDATE_PROFILE_COMPANY",
  UPDATE_PROFILE_COMPANY_SUCCESS: "UPDATE_PROFILE_COMPANY_SUCCESS",
  UPDATE_PROFILE_COMPANY_FAIL: "UPDATE_PROFILE_COMPANY_FAIL",

  UPDATE_PROFILE_USER: "UPDATE_PROFILE_USER",
  UPDATE_PROFILE_USER_SUCCESS: "UPDATE_PROFILE_USER_SUCCESS",
  UPDATE_PROFILE_USER_FAIL: "UPDATE_PROFILE_USER_FAIL",

  CLEAR_PROFILE: "CLEAR_PROFILE"
};
