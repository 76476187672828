import * as yup from "yup";

export const signInValidator = yup.object().shape({
  email: yup
    .string()
    .email("Please provide an e-mail address")
    .required("This field is required"),
  password: yup
    .string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("This field is required"),
});
