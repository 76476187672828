import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import { COLORS, ICONS_NAMES, SORT_BY } from "../../../constants/common";
import Icon from "../icon";

const ICON_SIZE = 28

const SortToggle = ({leadKey, isActiveSorting, setIsActiveSorting, limit, onClick }) => {

  const handleToggle = useCallback(() => {
    const order = isActiveSorting[leadKey] === SORT_BY.ASC? SORT_BY.DESC:SORT_BY.ASC
    setIsActiveSorting({[leadKey]: order})
    const requestParams = {
      sortBy: leadKey,
      order,
      limit
    }
    onClick(requestParams)
  },[leadKey, isActiveSorting, limit, onClick])

  const isOrderByASC = isActiveSorting[leadKey]===SORT_BY.ASC
  const isOrderByDESC = isActiveSorting[leadKey]===SORT_BY.DESC

  switch (isActiveSorting[leadKey]){
    case SORT_BY.ASC:
      return <Icon name={ICONS_NAMES.ASC}
                   color={COLORS.DARK}
                   className={`sort-icon ${isOrderByASC && "active"}`}
                   size={ICON_SIZE}
                   onClick={handleToggle}
      />;
    case SORT_BY.DESC:
      return <Icon name={ICONS_NAMES.DESC}
                   color={COLORS.DARK}
                   className={`sort-icon ${isOrderByDESC && "active"}`}
                   size={ICON_SIZE}
                   onClick={handleToggle}
      />;
    default:
      return <Icon name={ICONS_NAMES.ASC}
                   color={COLORS.DARK}
                   className={`sort-icon ${isOrderByASC && "active"}`}
                   size={ICON_SIZE}
                   onClick={handleToggle}
      />;
  }
};

SortToggle.propTypes = {
  leadKey: PropTypes.string.isRequired,
  isActiveSorting: PropTypes.shape({}).isRequired,
  setIsActiveSorting: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SortToggle;