import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./style.scss";

const ModalContainer = ({
  isOpen,
  children,
  closeModal,
  onSubmit,
  btnText,
  modalText,
  isConfirm,
  isInfo,
  className,
  showCloseButton
}) => {

  // HOOKS
  const { t } = useTranslation();

  useLayoutEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  // FUNCTIONS
  const submitHandler = () => {
    if (onSubmit && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    isOpen &&
    ReactDOM.createPortal(
      <div className={`modal_container ${className}`}>
        <div className="modal_container__body">
          {showCloseButton &&
            <button onClick={closeModal} className="modal_confirmation_close">
              {t("Close")}
            </button>
          }
          {children || (
            <h3 className="modal_container__body__title">{modalText}</h3>
          )}
          {isConfirm &&
             <div className="modal_confirmation__body__buttons_wrapper">
                <button className="btn btn-blue" onClick={submitHandler}>
                  {btnText.yes}
                </button>
                <button className="btn btn-outline-blue" onClick={closeModal}>
                  {btnText.no}
                </button>
            </div>
          }
          {isInfo &&
              <div className="modal_confirmation__body__buttons_wrapper">
                <button className="btn btn-blue" onClick={closeModal}>
                  {btnText.ok}
                </button>
              </div>
          }
        </div>
      </div>,
      document.body
    )
  );
};

ModalContainer.defaultProps = {
  isOpen: false,
  onSubmit: null,
  btnText: {
    yes: "Yes",
    no: "No",
    ok: "Ok"
  },
  modalText: "",
  children: null,
  isConfirm: false,
  isInfo: false,
  className: "",
  showCloseButton: true
};

ModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  btnText: PropTypes.shape({
    yes: PropTypes.string,
    no: PropTypes.string,
    ok: PropTypes.string,
  }),
  modalText: PropTypes.string,
  className: PropTypes.string,
  isConfirm: PropTypes.bool,
  isInfo: PropTypes.bool,

};

export default ModalContainer;
