export default {
  GET_CAMPAIGNS: "GET_CAMPAIGNS",
  GET_CAMPAIGNS_SUCCESS: "GET_CAMPAIGNS_SUCCESS",
  GET_CAMPAIGNS_FAIL: "GET_CAMPAIGNS_FAIL",

  GET_CAMPAIGN: "GET_CAMPAIGN",
  GET_CAMPAIGN_SUCCESS: "GET_CAMPAIGN_SUCCESS",
  GET_CAMPAIGN_FAIL: "GET_CAMPAIGN_FAIL",

  DELETE_CAMPAIGN: "DELETE_CAMPAIGN",
  DELETE_CAMPAIGN_SUCCESS: "DELETE_CAMPAIGN_SUCCESS",
  DELETE_CAMPAIGN_FAIL: "DELETE_CAMPAIGN_FAIL",

  UPDATE_CAMPAIGN: "UPDATE_CAMPAIGN",
  UPDATE_CAMPAIGN_SUCCESS: "UPDATE_CAMPAIGN_SUCCESS",
  UPDATE_CAMPAIGN_FAIL: "UPDATE_CAMPAIGN_FAIL",

  GET_CAMPAIGN_LEADS: "GET_CAMPAIGN_LEADS",
  GET_CAMPAIGN_LEADS_SUCCESS: "GET_CAMPAIGN_LEADS_SUCCESS",
  GET_CAMPAIGN_LEADS_FAIL: "GET_CAMPAIGN_LEADS_FAIL",

  GET_CAMPAIGNS_LEADS_REQUEST: "GET_CAMPAIGNS_LEADS_REQUEST",
  GET_CAMPAIGNS_LEADS_REQUEST_SUCCESS: "GET_CAMPAIGNS_LEADS_REQUEST_SUCCESS",
  GET_CAMPAIGNS_LEADS_REQUEST_FAIL: "GET_CAMPAIGNS_LEADS_REQUEST_FAIL",

  CLEAR_CURRENT_CAMPAIGN: "CLEAR_CURRENT_CAMPAIGN",
  CLEAR_CAMPAIGN_LEADS: "CLEAR_CAMPAIGN_LEADS",

  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  CREATE_CAMPAIGN_SUCCESS: "CREATE_CAMPAIGN_SUCCESS",
  CREATE_CAMPAIGN_FAIL: "CREATE_CAMPAIGN_FAIL",

  DELETE_LEAD: "DELETE_LEAD",
  DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",
  DELETE_LEAD_FAIL: "DELETE_LEAD_FAIL",

  UPDATE_LEAD: "UPDATE_LEAD",
  UPDATE_LEAD_SUCCESS: "UPDATE_LEAD_SUCCESS",
  UPDATE_LEAD_FAIL: "UPDATE_LEAD_FAIL",

  DELETE_VIDEO: "DELETE_VIDEO",
  DELETE_VIDEO_SUCCESS: "DELETE_VIDEO_SUCCESS",
  DELETE_VIDEO_FAIL: "DELETE_VIDEO_FAIL",

  CREATE_LEAD: "CREATE_LEAD",
  CREATE_LEAD_SUCCESS: "CREATE_LEAD_SUCCESS",
  CREATE_LEAD_FAIL: "CREATE_LEAD_FAIL",

  PUBLISH_CAMPAIGN: "PUBLISH_CAMPAIGN",
  PUBLISH_CAMPAIGN_SUCCESS: "PUBLISH_CAMPAIGN_SUCCESS",
  PUBLISH_CAMPAIGN_FAIL: "PUBLISH_CAMPAIGN_FAIL",

  SET_CAMPAIGNS_BY_STATUS: "SET_CAMPAIGNS_BY_STATUS",
  SET_LEADS_BY_CAMPAIGN: "SET_LEADS_BY_CAMPAIGN",
  SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
  CLEAR_CAMPAIGN_ENTITIES: "CLEAR_CAMPAIGN_ENTITIES",
  
  CREATE_CAMPAIGN_DUPLICATE: "CREATE_CAMPAIGN_DUPLICATE",
  CREATE_CAMPAIGN_DUPLICATE_SUCCESS: "CREATE_CAMPAIGN_DUPLICATE_SUCCESS",
  CREATE_CAMPAIGN_DUPLICATE_FAIL: "CREATE_CAMPAIGN_DUPLICATE_FAIL",


  REFRESH_CAMPAIGN_URL: "REFRESH_CAMPAIGN_URL",
  REFRESH_CAMPAIGN_URL_SUCCESS: "REFRESH_CAMPAIGN_URL_SUCCESS",
  REFRESH_CAMPAIGN_URL_FAIL: "REFRESH_CAMPAIGN_URL_FAIL",

  CLEAR_REFRESH_URL_STATUS: "CLEAR_REFRESH_URL_STATUS",

};
