import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CAMPAIGN_VIDEO_TYPES, ICONS_NAMES } from "../../../constants/common";
import { CampaignModel } from "../../../utils/helpers/defPropTypes";
import Icon from "../../common/icon";
import VideoPageItem  from "../videoPageItem";
import "./style.scss";

const VIDEO_LIMIT = 2;

const VideoPageListHeader = ({ campaign }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const campaignLeads = useSelector(
    ({ campaigns: { leadsEntities } }) => leadsEntities[campaign?.id]
  );

  return (
    <figure className="video-library__list">
      <div className="campaign-title">
        <figcaption>{campaign.campaign_title}</figcaption>
        {campaign.video_type === CAMPAIGN_VIDEO_TYPES.each &&
          campaignLeads?.data?.length > VIDEO_LIMIT && (
            <div
              key={`toggle${campaign.id}`}
              role="presentation"
              onClick={handleToggle}
              className="show-more"
            >
              <Icon
                color="#393f4d"
                className={`${isOpen && "selected"}`}
                size={10}
                name={ICONS_NAMES.DROPDOWN}
              />
              <span className="show-more__title">Show all videos</span>
            </div>
          )}
      </div>
      <ul>
        <VideoPageItem
          campaignLeads={campaignLeads?.data}
          key={`landingItem-${campaign.id}`}
          campaign={campaign}
          isOpen={isOpen}
        />
      </ul>
    </figure>
  );
};

VideoPageListHeader.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
};

export default VideoPageListHeader;
