import React from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextArea from "../../../common/textArea";
import { updateCampaignRequest } from "../../../../store/campaigns/operation";
import { CampaignModel } from "../../../../utils/helpers/defPropTypes";
import "./style.scss";
import {
  CAMPAIGN_STEPS_VALUE,
  FORM_INITIAL_VALUES,
} from "../../../../constants/common";
import { debounce } from "../../../../utils/helpers/common";

const WelcomeMessageForm = ({ campaign }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ ui: { requestLoading } }) => requestLoading);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      welcomeMessage: campaign?.welcome_text || "",
    },
    onSubmit: ({ welcomeMessage }) => {
      dispatch(
        updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          welcome_text: welcomeMessage,
        })
      );
    },
  });

  const setValueHandler = (name, value) => {
    formik.setFieldValue(name, value, true);
  };

  const updateWelcomeMessage = debounce(1000, ({ target: { name, value } }) => {
    setValueHandler(name, value);
    formik.submitForm();
  })();

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row m-b-30 form-wrapper">
        <p className="text-center m-b-20">
          {t("Add welcome message on 1st frame")}
        </p>
        <TextArea
          disabled={isLoading}
          placeholder={FORM_INITIAL_VALUES.WELCOME_MESSAGE}
          maxLength={75}
          id="welcomeMessage"
          error={formik.errors.welcomeMessage}
          defaultValue="Insert variable"
          name="welcomeMessage"
          onChange={updateWelcomeMessage}
          className="m-b-15"
          variables={campaign.fields}
          value={formik.values.welcomeMessage}
        />
      </div>
    </form>
  );
};

WelcomeMessageForm.defaultProps = {};

WelcomeMessageForm.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
};

export default WelcomeMessageForm;
