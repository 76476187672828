import * as yup from "yup";

// eslint-disable-next-line
const linkReg = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/g
const checkWhitespace = (value) => !/\s/g.test(value);

export const rules = yup.object().shape({
  image: yup.mixed().required(),
  title: yup.string().min(10).required(),
  content: yup.string().min(10).required(),
  button_title: yup.string().required("Please enter title").nullable(),
  button_url: yup
      .string()
      .matches(linkReg, "Enter correct url!")
      .required("Please enter website")
      .test("checkWhitespace","Dont use whitespace in url!", checkWhitespace)
});
