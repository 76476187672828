import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ui from "./ui";
import user from "./user";
import files from "./files";
import campaigns from "./campaigns";
import domains from "./domains";
import profile from "./profile";
import countries from './countries'
import teamMembers from "./team-members"

const uiPersistConfig = {
  key: "ui",
  storage,
  blacklist: [
    "error",
    "voiceTrainingCompletedSteps",
    "requestLoading",
    "menus",
    "sentences",
    "countdown",
    "authErrors",
    "redirectTo",
  ],
};
const userPersistConfig = { key: "user", storage, blacklist: ["user"] };
const campaignsPersistConfig = {
  key: "campaigns",
  storage,
  blacklist: ["current", "leads", "data", "campaignsEntities"],
};

const rootReducer = combineReducers({
  ui: persistReducer(uiPersistConfig, ui),
  user: persistReducer(userPersistConfig, user),
  campaigns: persistReducer(campaignsPersistConfig, campaigns),
  files,
  domains,
  profile,
  countries,
  teamMembers
});

export default rootReducer;
