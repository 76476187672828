import React, {useCallback, useMemo, useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { equals, path } from "ramda";
import { deleteFile } from "../../../../store/files/operation";
import {
  addNewLead,
  addNewLeadsList,
  deleteLeadRequest,
  updateLeadFields,
  updateCampaignRequest,
  getLeadsRequest, 
  updateCampaignSortingRequest,
  deleteLeads,
  deleteCampaignRequest,
  refreshCampaignUrlRequest
} from "../../../../store/campaigns/operation";
import { campaignsAction } from "../../../../store/campaigns";
import {
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
  ICONS_NAMES,
  CAMPAIGN_STATUSES,
  START_ROUTE_LINK,
  MODALS_TEXTS,
  SORT_BY
} from "../../../../constants/common";
import Icon from "../../../common/icon";
import Loader from "../../../common/loader";
import { CampaignModel } from "../../../../utils/helpers/defPropTypes";
import EditLeadButtonsBlock from "./editLeadButtonsBlock";
import DeleteLeadButtonsBlock from "./deleteLeadButtonsBlock";
import SortToggle from "../../../common/sortToggle";
import ModalContainer from "../../../../containers/modal";
import { useModal } from "../../../../utils/helpers/useModal";
import "./style.scss";

// CONST
const DEFAULT_SORT_KEY = "row_id"
const NULL = "null"
const DEFAULT_LEAD_LIMIT = 100
const defaultSortParams = {
  sortBy: DEFAULT_SORT_KEY,
  order: SORT_BY.ASC
}

const LeadsAdded = ({ leads, campaign }) => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef();
  const { refreshUrlStatus, userToken, loading } = useSelector(
    ({ campaigns: { status }, user: { token }, ui: { requestLoading } }) => ({
      refreshUrlStatus: status,
      userToken: token,
      loading: requestLoading,
    })
  );
  const [leadIsEdited, setLeadIsEdited] = useState({});
  const [leadIsChecked, setLeadIsChecked] = useState({});
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [sortingData, setSortingData] = useState({[campaign.sorting_attribute]: campaign.sorting_order});
  const [editedLeadFields, setEditedLeadFields] = useState(null);
  const [modalData, setModalData] = useState({
    onSubmit: null,
    modalText: "",
  });
  const {toggleModal: toggleModalDelete, isOpenModal: isOpenModalDelete, closeModal: closeModalDelete} = useModal();
  const {toggleModal: toggleModalUpdate, isOpenModal: isOpenModalUpdate} = useModal();

  // CONST
  const { id: campaignId, fields: campaignFields, status } = campaign;
  const isDraftCampaign = campaign.status === CAMPAIGN_STATUSES.draft.id
  const isSingleLead = leads?.length === 1;
  const firstLeadId = leads && leads[0]?.id;
  const firstLeadWithoutVideoId = leads?.find((item) => !item.video_id)?.id;
  const linkToVideos = `/campaigns/${campaign.id}/videos`;
  const linkToVideosLead = `/campaigns/${campaign.id}/videos/${firstLeadWithoutVideoId || firstLeadId}`;
  const downloadCSVFileUrl = `${
    process.env.REACT_APP_API_URL
  }/api/campaigns/${path(["id"], campaign)}/csv-urls?token=${userToken}`;

  const updateCampaign = (type) => {
    dispatch(
      updateCampaignRequest({
        id: campaign.id,
        step: CAMPAIGN_STEPS_VALUE.VIDEOS,
        video_type: type,
      })
    );
  };

  const closeModal = () => {
    closeModalDelete();
    setModalData({
      onSubmit: null,
      modalText: "", 
    });
  };  
  const updateUrlDispatch = () => dispatch(refreshCampaignUrlRequest(campaignId))

  const closeModalUrl = () => {
    toggleModalUpdate();
    dispatch(campaignsAction.clearRefreshUrlStatus());
  };

  const editLineHandler = (id, fields) => {
    setEditedLeadFields({ ...fields });
    setLeadIsEdited({ [id]: true });
  };

  const saveLineHandler = (id, fields) => {
    if (!equals(editedLeadFields, fields)) {
      dispatch(updateLeadFields(campaignId, id, { fields: editedLeadFields }));
    }
    setEditedLeadFields(null);
    setLeadIsEdited({});
    if( status !== CAMPAIGN_STATUSES.draft.id) {
      toggleModalUpdate();
    }
  };

  useEffect(() => {
    return ()=>{
      dispatch(campaignsAction.clearRefreshUrlStatus());
    }
  }, [])

  const addNewLineHandler = () => {
    dispatch(addNewLead(campaign.id));
  };

  const deleteLineHandler = (data) => {
    dispatch(deleteLeadRequest(campaignId, data));
    setEditedLeadFields(null);
    setLeadIsEdited({});
    if(isSingleLead){
      updateCampaign(CAMPAIGN_VIDEO_TYPES.generic)
    }
    closeModal();

  };

  const getFileHandler = ({ target: { files } }) => {
    dispatch(deleteFile("csv"));
    if (files) {
      const [file] = files;
      dispatch(addNewLeadsList(file, campaign));
    }
  };

  const addNewLeadsConfirmation = () => {
    inputRef.current.click();
    closeModal();
  };

  const deleteCampaign = () => {
    dispatch(deleteCampaignRequest(campaign.id));
    closeModal();
    history.push(START_ROUTE_LINK);

  };

  const deleteLead = (id) => {
    toggleModalDelete()
    if(!isDraftCampaign && isSingleLead){
      setModalData({
        onSubmit: () => deleteCampaign(),
        modalText: t(MODALS_TEXTS.DELETE_LAST_LEAD),
      });
    } else {
      setModalData({
        onSubmit: () => deleteLineHandler(id),
        modalText: t(MODALS_TEXTS.DELETE_LEAD),
      });
    }
    
  };

  const deleteLeadsList = () => {
    toggleModalDelete()
    setModalData({
      onSubmit: addNewLeadsConfirmation,
      modalText: t(MODALS_TEXTS.DELETE_ALL_LEADS),
    });
  };

  const confirmBulkDelete = (allLeadsSelected, ids, singleLeadSelected) => {
    toggleModalDelete()
    if(!isDraftCampaign && allLeadsSelected) {
      setModalData({
        onSubmit: () => deleteCampaign(),
        modalText: t(MODALS_TEXTS.DELETE_ALL_LEADS),
      });
    }
    else {
      setModalData({
        onSubmit: () => {
          dispatch(deleteLeads(campaignId, ids))
          closeModal()
        },
        modalText: t(`Are you sure you want to delete selected ${singleLeadSelected? "lead": "leads"}?`),
      });
    }
  };

  const nextToVideosHandler = () => {
    switch (campaign.video_type) {
      case CAMPAIGN_VIDEO_TYPES.none:
        if(isSingleLead) {
          updateCampaign(CAMPAIGN_VIDEO_TYPES.generic);
        }
        history.push(linkToVideos);
        break;
      case CAMPAIGN_VIDEO_TYPES.generic:
        if(campaign.status !== CAMPAIGN_STATUSES.draft.id){
           updateCampaign(CAMPAIGN_VIDEO_TYPES.generic);
           history.push(linkToVideos);
        }
        if(campaign.step === CAMPAIGN_STEPS_VALUE.VIDEOS) {
          updateCampaign(CAMPAIGN_VIDEO_TYPES.generic);
          history.push(linkToVideos);
        }
        history.push(linkToVideos);
        break;
      case CAMPAIGN_VIDEO_TYPES.each:
        if(isSingleLead) {
          updateCampaign(CAMPAIGN_VIDEO_TYPES.generic);
          history.push(linkToVideos);
        } 
        if(campaign.status !== CAMPAIGN_STATUSES.draft.id) {
            updateCampaign(CAMPAIGN_VIDEO_TYPES.each);
            history.push(linkToVideosLead);
          
        }
        if(campaign.step === CAMPAIGN_STEPS_VALUE.VIDEOS) {
            updateCampaign(CAMPAIGN_VIDEO_TYPES.each); 
            history.push(linkToVideosLead);
        } 
        history.push(linkToVideosLead);
        break;
      default:
        history.push(linkToVideos);
    }
  }
  const findEditedLead = (leadId) => {
    const { fields } =  leads.find(({id}) => id === leadId)
    return fields
  }
  const resetBulkDelete = () => {
    setIsBulkDelete(false)
    setLeadIsChecked({})
  };

  const onEditLead = (value, key) => setEditedLeadFields(prevState=>({...prevState, [key]: value}))
  const onCheckLead = (value, id) => setLeadIsChecked(prevState => ({...prevState,  [id]: value }));
  const leadDataWasChanged = (id) => !equals(findEditedLead(id), editedLeadFields)
  const activateBulkDelete = () => setIsBulkDelete(true)
  const clearEditedLeadData = () => setLeadIsEdited({})
  const [sortBy] =  Object.keys(sortingData)

  const isActiveSorting = useMemo(() => {
    return sortBy && sortBy !== DEFAULT_SORT_KEY && sortBy !== NULL
  },[sortBy])

  const deleteSelectedLeads = useCallback(() => {
    const ids = []
    const data =  Object.entries(leadIsChecked);
    // eslint-disable-next-line no-restricted-syntax
    for ( const info of data ) {
      const [leadId, isChecked] = info
      if (isChecked) ids.push(leadId)
    }
    if (ids.length) {
      const isSingleLeadSelected = ids.length === 1;
      const isAllLeadsSelected = ids.length === leads.length;
      confirmBulkDelete(isAllLeadsSelected, ids, isSingleLeadSelected)
    }
    resetBulkDelete()
  }, [leadIsChecked, campaignId])

  const onSortClick = useCallback((requestParams)=>{
    dispatch(getLeadsRequest(campaignId, requestParams))
    dispatch(updateCampaignSortingRequest(campaign, requestParams.sortBy, requestParams.order))
  },[campaignId])

  const onCancelSortClick = useCallback(() => {
    setSortingData({[defaultSortParams.sortBy]: defaultSortParams.order})
    dispatch(updateCampaignSortingRequest(campaign, DEFAULT_SORT_KEY, SORT_BY.ASC))
    dispatch(getLeadsRequest(campaignId, defaultSortParams))
  },[campaignId])

  if (loading) return <Loader />;

  return (
    <div className="leads-container">
      <h1 className="add-leads-title">{`${t("The following")} ${
        isSingleLead ? t("lead") : t("leads")
      } ${t("have been added")}`}</h1>
      <table className="leads-container__tab">
        <thead>
          <tr className="leads-container__tab__header">
            {campaignFields.map(({ key, title }) => (
              <th key={key}>
                <div className="lead-key-wrapper">
                  <p>{title}</p>
                  <SortToggle
                    onClick={onSortClick}
                    limit={DEFAULT_LEAD_LIMIT}
                    isActiveSorting={sortingData}
                    setIsActiveSorting={setSortingData}
                    leadKey={key}
                  />
                </div>
              </th>
            ))}
            <th className="control-col">{t("Edit")}</th>
            { isActiveSorting &&
            <th className="cancel-sort">
              <button className="btn btn-outline-dark cancel" onClick={onCancelSortClick}>
                {t("Cancel")}
              </button>
            </th>
            }
          </tr>
        </thead>
        <tbody>
          {leads.map(({ id, fields }) => (
            <tr key={`leadRow${id}`} className="leads-container__tab__row">
              {campaignFields.map(({ key }) =>
                leadIsEdited[id] ? (
                  <td key={`leadItem${key}`}>
                    <input
                      type="text"
                      onChange={({currentTarget: { value}}) => onEditLead(value, key)}
                      defaultValue={fields[key]}
                    />
                  </td>
                ) : (
                  <td key={`leadItem${key}`}>{fields[key]}</td>
                )
              )}
              <td>
                {leadIsEdited[id] ?
                    <EditLeadButtonsBlock
                        onSave={saveLineHandler}
                        fields={fields}
                        onCancel={clearEditedLeadData}
                        leadDataWasChanged={leadDataWasChanged(id)}
                        id={id}
                    />
                    : (
                        <div className="leads-container__tab__row__action">
                          <button onClick={() => editLineHandler(id, fields)}>
                            <Icon name={ICONS_NAMES.EDIT} size={20} color="#393f4d" />
                            <span>Edit</span>
                          </button>
                        </div>
                    )}
              </td>
              <td className="leads-container__tab__row__action">
                <DeleteLeadButtonsBlock
                    isChecked={leadIsChecked[id]}
                    deleteLead={deleteLead}
                    onCheckLead={onCheckLead}
                    isBulkDelete={isBulkDelete}
                    id={id}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="tab-footer">
        <div className="buttons-line">
          <button className="add-line-button" onClick={addNewLineHandler}>
            <Icon
                size={35}
                name={ICONS_NAMES.CROSS_CIRCLE}
                className="add-line-icon"
                color="#393f4d"
            />
            <span>{t("Add line")}</span>
          </button>
          {isBulkDelete
              ? <div className="delete-buttons-wrapper">
                <button className="btn btn-outline-dark delete"
                        onClick={deleteSelectedLeads}>{t("Delete leads now")}</button>
                <button className="btn btn-outline-dark cancel"
                        onClick={resetBulkDelete}>{t("Cancel")}</button>
              </div>
              : <button className="btn btn-outline-dark bulk-delete"
                      onClick={activateBulkDelete}>{t("Bulk delete")}</button>
          }
        </div>
        </tfoot>
      </table>

      <div className="leads-container__btn-wrapper">
        <button className="btn btn-blue" onClick={nextToVideosHandler}>
          {`${t("Next to create")} ${isSingleLead ? t("video") : t("videos")}`}
        </button>
        <label
          role="presentation"
          htmlFor={inputRef}
          onClick={deleteLeadsList}
          className="add-new-csv delete-list btn-outline-blue btn"
        >
          <Icon
            size={30}
            name={ICONS_NAMES.CROSS_CIRCLE}
            className="delete-list-icon"
            color="#1f86ff"
          />
          {t("Delete list and add new")}
        </label>
        <input
          className="add-new-leads-input"
          onChange={getFileHandler}
          ref={inputRef}
          type="file"
          id="file"
          accept=".csv"
        />
      </div>
      <ModalContainer
        isOpen={isOpenModalDelete}
        closeModal={closeModal}
        onSubmit={modalData.onSubmit}
        isConfirm
        btnText={{ yes: "Yes", no: "Not now" }}
        modalText={modalData.modalText}
      />
      <ModalContainer isOpen={isOpenModalUpdate} closeModal={closeModalUrl}>
        {refreshUrlStatus === 200 ? (
          <>
          <h3 className="modal_confirmation__body__title">
              {t("Your URLs have been updated. You can download your new links below")}
            </h3>
          <div className="modal_confirmation__body__buttons_wrapper btn_wrap">
            <a
              className="btn btn-blue btn-block m-b-15"
              href={downloadCSVFileUrl}
            >
              {t("Download CSV with URLs")}
            </a>
          </div>
          </>
        ) : (
          <>
            <h3 className="modal_confirmation__body__title">
              {t("We've seen that you changed some entries in your table. Would you like to refresh URLs now?")}
            </h3>
            <div className="modal_confirmation__body__buttons_wrapper">
              <button className="btn btn-blue" onClick={() => updateUrlDispatch()}>
                {t("Yes")}
              </button>
              <button className="btn btn-blue" onClick={closeModalUrl}>
                {t("No")}
              </button>
            </div>
          </>

        )}
        
      </ModalContainer>
    </div>
  );
};

LeadsAdded.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  campaign: PropTypes.shape(CampaignModel).isRequired,
};

export default LeadsAdded;
