import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { filter, find, findIndex, propEq } from "ramda";
import { campaignsOperation } from "../../store/campaigns";

export const useCampaignAndLeads = () => {
  const dispatch = useDispatch();
  const { campaign, leads } = useSelector(({ campaigns }) => ({
    campaign: campaigns.current,
    leads: campaigns.leads,
  }));

  useEffect(() => {
    if (campaign && campaign.id && (!leads || !leads.length)) {
      dispatch(campaignsOperation.getLeadsRequest(campaign.id));
    }
  }, [campaign, campaignsOperation.getLeadsRequest]);

  if (!leads) return { campaign, leads: null };

  return { campaign, leads };
};

export const useActiveLeadByParams = (leads, active) => {
  const { entity } = useParams();
  const [activeLead, setActiveLead] = useState(
    leads && leads.length ? leads[0] : null
  );

  const getActiveLead = () => {
    if (entity && leads && leads.length && !active) {
      const findLead = find(propEq("id", +entity))(leads);
      setActiveLead(findLead || leads[0]);
    } else if (!entity && leads?.length && !active) {
      const lastLeadWithVideoIndex = filter((a) => a.video_id, leads).length;
      const lastActiveLead =
        leads.length === lastLeadWithVideoIndex
          ? leads[0]
          : leads[lastLeadWithVideoIndex];
      setActiveLead(lastActiveLead);
    }
  };

  useEffect(() => {
    getActiveLead();
  }, [entity, leads]);

  if (active && active.fields) {
    return {
      active,
      index: active ? findIndex(propEq("id", active.id))(leads) + 1 : 1,
    };
  }

  return {
    active: activeLead || (leads && leads.length ? leads[0] : null),
    index:
      activeLead && leads && leads.length
        ? findIndex(propEq("id", activeLead.id))(leads) + 1
        : 1,
  };
};
