import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import configureStore from "./store/store";
import AppRoutes from "./routes/appRoutes";
import { Loader, Notifications } from "./components";
import CountdownCounter from "./components/common/countdownCounter";
import BlockSiteIsLoading from "./components/common/blockSiteIsLoading";
import "./App.scss";

const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <PersistGate loading={<Loader />} persistor={persistStore(store)}>
          <BrowserRouter>
            <BlockSiteIsLoading />
            <AppRoutes />
            <Notifications />
            <CountdownCounter />
          </BrowserRouter>
        </PersistGate>
      </div>
    </Provider>
  );
};

export default App;
