import { handleActions } from "redux-actions";
import { mergeIn } from "../../utils/helpers/stateHelpers";
import types from "./types";

const initialState = {
  current: null,
  domainsList: null,
};

const reducer = handleActions(
  {
    [types.CREATE_DOMAIN_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { model },
        },
      }) => ({
        current: model,
      })
    ),
    [types.GET_DOMAINS_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      domainsList: data,
    })),
    [types.CLEAR_CURRENT_DOMAIN]: mergeIn(() => ({ current: null })),
      [types.SET_CURRENT_DOMAIN]: mergeIn(({ payload: model }) => ({ current: model })),
      [types.SET_DOMAINS]: mergeIn(({ payload: { data } }) => ({
          domainsList: data,
      })),
  },
  initialState
);

export default reducer;
