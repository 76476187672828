import React from "react";
import { NavLink, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { ICONS_NAMES, OBJECT_KEY } from "../../../../constants/common";
import Icon from "../../../common/icon";
import "./style.scss";

const LeadsVideoList = ({ leads, className }) => {
  const { entity } = useParams();

  const entityListLeads = leads?.filter((item) => item.id !== +entity);

  return (
    <div className={`lead-video-list-container ${className}`}>
      <div className="lead-video-list">
        <ul>
          {entityListLeads?.map((lead) => (
            <li className="lead-video-item" key={`LeadsVideoList${lead.id}`}>
              <span>
                {`Video ${lead.index} for
                ${Object.values(lead.fields)[OBJECT_KEY.FIRST]},
                ${Object.values(lead.fields)[OBJECT_KEY.SECOND]}`}
              </span>
              <div>
                <NavLink
                  to={`/campaigns/${lead.campaign_id}/videos/${lead.id}`}
                  className="edit-button"
                >
                  <Icon
                    className="edit-icon"
                    name={ICONS_NAMES.EDIT}
                    size={25}
                    alt="edit"
                    color="#393f4d"
                  />
                  Edit
                </NavLink>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

LeadsVideoList.defaultProps = {
  className: "",
};
LeadsVideoList.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  className: PropTypes.string,
};

export default LeadsVideoList;
