import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { TABS_CLASSES } from "../../../../constants/common";
import "./style.scss";

const CampaignTabsItem = ({ active, campaignId, label, to, state }) => {

   // CONST
   const pathname = `/campaigns${Number(campaignId) ? `/${campaignId}` : ""}${to && `/${to}`}`;

   // FUNCTIONS  
  const isDisabled = (e) => {
    if (active === TABS_CLASSES.DISABLED_CLASS) {
      e.preventDefault();
    }
  };

  return (
    <NavLink
      onClick={isDisabled}
      className={active}
      to={{ pathname, state }}
    >
      {label}
    </NavLink>
  );
};

CampaignTabsItem.defaultProps = {
  state: null,
};
CampaignTabsItem.propTypes = {
  active: PropTypes.string.isRequired,
  campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

export default CampaignTabsItem