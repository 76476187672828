import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { campaignsOperation } from "../../../../store/campaigns";
import {
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
} from "../../../../constants/common";
import { useCampaignAndLeads} from "../../../../utils/helpers/campaignHooks";
import "./style.scss";

const VideoSelectTypeScreen = () => {

  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const { leads, campaign } = useCampaignAndLeads();
  const loading = useSelector(({ ui: { requestLoading } }) => requestLoading);

  // CONST
  const firstLeadId = leads && leads[0]?.id;
  const campaignId = campaign.id;

  // FUNCTIONS
  const updateCampaign = (type) => {
    if (campaign) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaignId,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          video_type: type,
        })
      );
      if(type === CAMPAIGN_VIDEO_TYPES.each) {
        history.push(`/campaigns/${campaignId}/videos/${firstLeadId}`);
      }
    }
  }

  return (
    <div className="VideoSelectTypeScreen">
      <p className="pageSubTitle m-b-25">
        Choose from one of the following options
      </p>

      <div className="VideoSelectTypeScreen_wrap">
        <button className="btn" onClick={() => updateCampaign(CAMPAIGN_VIDEO_TYPES.each)} disabled={loading}>
          Record/upload a video for each lead
        </button>
        <p className="danger">
          For each record/upload the leads in your list will be shown and then
          saved.
        </p>
        <button
          className="btn btn-blue"
          onClick={() => updateCampaign(CAMPAIGN_VIDEO_TYPES.generic)}
          disabled={loading}
        >
          Record/upload a generic video
        </button>
        <p className="danger">
          Record/upload one single video for all leads and personalize with an
          overlay optionally.
        </p>
      </div>
    </div>
  );
};


export default VideoSelectTypeScreen;
