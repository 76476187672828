import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";

const CountdownCounter = () => {
  const countdown = useSelector(({ ui }) => ui.countdown);

  if (!countdown) return null;

  return (
    <div className="CountdownCounter">
      <div className="CountdownCounter_wrap">
        <p>{countdown}</p>
      </div>
    </div>
  );
};

export default CountdownCounter;
