import { useState } from "react"

export const useModal = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

   
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);

  };

  // use closeModal if need strict closed
  const closeModal = () => {
    setIsOpenModal(false);

  };

  return {isOpenModal, toggleModal, closeModal};
};