import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS,
  CAMPAIGN_STEPS_VALUE,
  ICONS_NAMES,
  OBJECT_KEY,
} from "../../../constants/common";
import { CampaignModel, LeadModel } from "../../../utils/helpers/defPropTypes";
import Icon from "../../common/icon";

const VideoPageListItem = ({ campaign, lead, isGenericVideoType }) => {
  const { t } = useTranslation();

  const link = `/campaigns/${campaign.id}/${[
    CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.VIDEOS],
  ]}/${!isGenericVideoType ? lead?.id : ""}`;
  const previewUrlGeneric = `${process.env.REACT_APP_API_URL}/uploads/videos/${campaign.video?.video_file_id?.filename}`;
  const previewUrlEach = `${process.env.REACT_APP_API_URL}/uploads/videos/${lead?.video?.video_file_id.filename}`;

  const fields = !!lead && Object.values(lead.fields);

  const videoTitleGeneric = lead ? `Video ${lead?.row_id}` : "Video 1";
  const videoTitleEachLead = ` for ${fields[OBJECT_KEY.FIRST]}, ${
    fields[OBJECT_KEY.SECOND]
  }`;

  const title = isGenericVideoType
    ? videoTitleGeneric
    : videoTitleGeneric + videoTitleEachLead;

  const previewLink = isGenericVideoType ? previewUrlGeneric : previewUrlEach;

  return (
    <li
      key={`lead-${lead?.id}`}
      className={`video-page-item ${
        campaign.status === CAMPAIGN_STATUSES.inactive.id && "inactive"
      }`}
    >
      <a
        target="_blank"
        rel="noreferrer"
        className="page-link"
        href={previewLink}
      >
        {title}
      </a>
      <NavLink
        to={{ pathname: link, state: { isEdit: true } }}
        className="video-page-item_edit"
      >
        <Icon
          name={ICONS_NAMES.EDIT}
          color="inherit"
          size={20}
          className="m-r-5"
        />
        <span>{t("Edit")}</span>
      </NavLink>
    </li>
  );
};

VideoPageListItem.defaultProps = {
  isGenericVideoType: false,
  lead: null,
};

VideoPageListItem.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
  lead: PropTypes.shape(LeadModel),
  isGenericVideoType: PropTypes.bool,
};

export default VideoPageListItem;
