// Show credentials in https://reqres.in/

export default {
  login: `/api/auth/login`,
  register: "/api/auth/register",
  currentUser: "/api/auth/current-user",
  forgotPassword: "/api/auth/forgot-password",
  recoveryPassword: "/api/auth/recovery-password",
  defaultCredentials: {
    email: "testemail@gmail.com",
    password: "12341234",
  },
  files: "/api/files",
  videoPageTemplates: "/api/video-page-templates",
  videoTemplate: "/api/video-templates",
  menu: "/api/menus",
  preview: "/preview-campaign",
  campaign: "/api/campaigns",
  profile: "/api/profile",
  updateUserProfile: "/api/profile/user",
  updateCompanyProfile: "/api/profile/company",
  publishCampaign: "/api/campaigns/publish",
  customDomains: "/api/custom-domains",
  countries: "/api/countries",
  setMemberPassword: "api/set-member-password",
  members: "api/members",
  inviteMember: "api/invite-member",
  resendInvite: "api/resend-invite"
};
