import * as yup from "yup";

export const signUpValidator = yup.object().shape({
  first_name: yup.string().required("This field is required."),
  last_name: yup.string().required("This field is required."),
  email: yup
    .string()
    .email("Please provide an e-mail address")
    .required("This field is required."),
  password: yup
    .string()
    .min(8, "Too Short!")
    .max(20, "Too Long!")
    .required("Please enter a password."),
  company_name: yup.string().required("This field is required."),
  agree: yup.bool().oneOf([true], "You need to accept our T&C"),
});
