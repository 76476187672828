import types from "./types";
import apiUrls from "../../constants/apiUrls";


const getCountriesRequest = () => ({
  type: types.GET_COUNTRIES,
  payload: {
    request: { url: apiUrls.countries, method: "GET" },
  },
});

export {
  getCountriesRequest,
};
