export default {
  CREATE_DOMAIN: "CREATE_DOMAIN",
  CREATE_DOMAIN_SUCCESS: "CREATE_DOMAIN_SUCCESS",
  CREATE_DOMAIN_FAIL: "CREATE_DOMAIN_FAIL",

  GET_DOMAINS: "GET_DOMAINS",
  GET_DOMAINS_SUCCESS: "GET_DOMAINS_SUCCESS",
  GET_DOMAINS_FAIL: "GET_DOMAINS_FAIL",

  CLEAR_CURRENT_DOMAIN: "CLEAR_CURRENT_DOMAIN",
  SET_CURRENT_DOMAIN: "SET_CURRENT_DOMAIN",
  SET_DOMAINS: "SET_DOMAINS",
};
