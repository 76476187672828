import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import {
  CONTACT_IMPRINT_LINK,
  TERMS_PRIVACY_LINK,
} from "../../../constants/common";

const AuthFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="AuthFooter">
      <div className="AuthFooter_left">
        <a target="_blank" rel="noreferrer" href={TERMS_PRIVACY_LINK}>
          {t("T & C")}
        </a>
        <a target="_blank" rel="noreferrer" href={CONTACT_IMPRINT_LINK}>
          {t("Contact & Imprint")}
        </a>
      </div>
      <div className="AuthFooter_right">
        <p>{t("© 2019-2021 by parruu AG")}</p>
      </div>
    </div>
  );
};

export default AuthFooter;
