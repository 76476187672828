import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import  TextArea from "../../../common/textArea";
import Icon from "../../../common/icon";
import { ICONS_NAMES } from "../../../../constants/common";
import { CampaignModel } from "../../../../utils/helpers/defPropTypes";
import { DOMAIN_ARRAY_INFO } from './domainInfoData';
import "./style.scss"

const DomainInfoSettings = ({ campaign, setFieldValue, errors, initialValues }) => {
    
    // FUNCTION
    const onChangeValue = ({ target: { name, value } }) => {
        setFieldValue(
        name,
        value,
        );
    };
    return ( 
        <div className="domainWrap">
            <div className="mainInfo formWrap">
                <div className="titleWrap">
                    <p className="title">Publishing settings</p>
                    <Link to="/domains" className="linkWrap">
                        <Icon
                            name={ICONS_NAMES.DOMAINS}
                            color="inherit"
                            size={20}
                            className="m-r-5"
                        />
                        <p>Add custom domain</p>
                    </Link>
                </div>
                <div className="descriptionWrap">
                    <span>!</span>
                    <p>Please check the info on URL and SEO settings below</p>
                </div>
            </div>  
            {DOMAIN_ARRAY_INFO.map(({title, main, info}) => (
                <div className="formWrap" key={`${title}-wrap`}>
                    <div className="formWrapInfo">
                        <h5>{title}</h5>
                        {main.map((el)=> (
                            <div className="rowInfo" key={`${el.name}-row`}>
                                <div className="textWrap">
                                    <h5>{el.subTitle}</h5>
                                    <p>{el.description}</p>
                                </div>
                                <TextArea
                                    name={el.name}
                                    type="text"
                                    maxLength={el.maxLength}
                                    variables={campaign?.fields}
                                    onChange={onChangeValue}
                                    defaultValue="Insert variable"
                                    error={errors[el.name]}
                                    value={initialValues[el.name]}
                                    placeholder=""
                                />
                            </div>
                        ))}
                        
                    </div>
                    <div className="formWrapDescription">
                        <ul>
                           {info.map((itm)=> <li key={itm}>{itm}</li>)}
                        </ul>
                    </div>
                </div>
            ))}
        </div>  
    )
}
DomainInfoSettings.defaultProps = {
  campaign: {},
  setFieldValue: null,
  errors: {}, 
  initialValues: {},
} 
DomainInfoSettings.propTypes = {
  campaign: PropTypes.shape(CampaignModel),
  setFieldValue: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};
export default DomainInfoSettings