export default {

  SET_MEMBER_PASSWORD: "SET_MEMBER_PASSWORD",
  SET_MEMBER_PASSWORD_SUCCESS: "SET_MEMBER_PASSWORD_SUCCESS",
  SET_MEMBER_PASSWORD_FAIL: "SET_MEMBER_PASSWORD_FAIL",

  CREATE_MEMBER: "CREATE_MEMBER",
  CREATE_MEMBER_SUCCESS: "CREATE_MEMBER_SUCCESS",
  CREATE_MEMBER_FAIL: "CREATE_MEMBER_FAIL",

  GET_MEMBERS: "GET_MEMBERS",
  GET_MEMBERS_SUCCESS: "GET_MEMBERS_SUCCESS",
  GET_MEMBERS_FAIL: "GET_MEMBERS_FAIL",

  DELETE_MEMBER: "DELETE_MEMBER",
  DELETE_MEMBER_SUCCESS: "DELETE_MEMBER_SUCCESS",
  DELETE_MEMBER_FAIL: "DELETE_MEMBER_FAIL",

  UPDATE_MEMBER: "UPDATE_MEMBER",
  UPDATE_MEMBER_SUCCESS: "UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAIL: "UPDATE_MEMBER_FAIL",

  RESEND_INVITE: "RESEND_INVITE",
  RESEND_INVITE_SUCCESS: "RESEND_INVITE_SUCCESS",
  RESEND_INVITE_FAIL: "RESEND_INVITE_FAIL",

};
