import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Icon from "../../../common/icon";
import { ICONS_NAMES } from "../../../../constants/common";

const ICON_SIZE = 30

const DeleteLeadButtonsBlock = ({isBulkDelete, onCheckLead, isChecked , id, deleteLead}) => {
    const { t } = useTranslation();
    return isBulkDelete
        ?
        <label className="container">
            <input
                onChange={({currentTarget: { checked }}) => onCheckLead(checked, id)}
                type="checkbox"
                checked={isChecked}
            />
            <span className="checkmark"/>
        </label>
        :
        <button onClick={() => deleteLead(id)}>
            <Icon
                size={ICON_SIZE}
                name={ICONS_NAMES.CROSS_CIRCLE}
                color="#393f4d"
            />
            <span>{t("Delete")}</span>
        </button>

};

DeleteLeadButtonsBlock.propTypes = {
    isBulkDelete: PropTypes.bool.isRequired,
    onCheckLead: PropTypes.func.isRequired,
    deleteLead: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isChecked: PropTypes.bool.isRequired
};

export default DeleteLeadButtonsBlock;