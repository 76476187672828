import React, { useEffect, useState } from "react";
import useMediaRecorder from "@wmik/use-media-recorder";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCampaignAndLeads } from "../../../../utils/helpers/campaignHooks";
import { useCountdown } from "../../../../store/ui/hooks";
import CampaignVideosLeadSteps from "../campaignVideosLeadSteps";
import {
  deleteLeadVideo,
  uploadVideoAndUpdateLead,
} from "../../../../store/campaigns/operation";
import VideoControls from "../videoControls";
import SelectRecordOption from "../selectRecordOption";
import FileUpload from "../../../common/fileUpload";
import VideoPlayer from "../../../common/videoPlayer";
import RecordingPreview from "../recordingPreview";
import LeadsVideoList from "../leadsVideoList";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
  ICONS_NAMES,
  OBJECT_KEY,
  RECORDER_STATUS,
  VIDEO_RATIO_16_9,
  VIDEO_WEBM,
  CAMPAIGN_STEPS
} from "../../../../constants/common";
import { campaignsOperation } from "../../../../store/campaigns";
import ModalContainer from "../../../../containers/modal";
import { getBlobVideoType } from "../../../../utils/helpers/stringHelper";
import { checkRecordingExceptions } from "../../../../utils/helpers/common";
import { useModal } from "../../../../utils/helpers/useModal";
import Icon from "../../../common/icon";
import "./style.scss";


const VideoEachTypeScreen = () => {

  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const { entity } = useParams()
  const { t } = useTranslation();
  const countdown = useCountdown();
  const { leads, campaign } = useCampaignAndLeads();
  const [withVideo, setWithVideo] = useState([]);
  const [recordScreen, setRecordScreen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [activeLead, setActiveLead] = useState(null);
  const {toggleModal, isOpenModal} = useModal();

  // CONST
  const RECORDING_DELAY = 500;
  const START_INDEX = 1;
  const LEADS = leads?.length;
  const WITH_VIDEO = withVideo?.length;
  const activeLeadIndex = leads?.findIndex((item) => item.id === activeLead?.id) + START_INDEX;
  const currentLeadVideoSrc = activeLead?.video &&
      `${process.env.REACT_APP_API_URL}/uploads/videos/${activeLead?.video.video_file_id.filename}`;

  const videoNumber = `Video ${activeLeadIndex}`;
  const videoDescription = activeLead?.fields &&
      ` for ${Object.values(activeLead?.fields)[OBJECT_KEY.FIRST]}, ${Object.values(activeLead?.fields)[OBJECT_KEY.SECOND]}`
  const btnStatus = LEADS === WITH_VIDEO ? t("Next to landing pages") : t("Next lead");
  const linkToVideos = `/campaigns/${campaign.id}/videos`;
  const linkToLandingPage = `/campaigns/${campaign.id}/${CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.LANDING_PAGES]}`;
  const videoSrc = currentLeadVideoSrc || "";
  const isBtnStatus = WITH_VIDEO > 0 && activeLead?.video_id;
  const videoId = activeLead?.video_id;

  // FUNCTIONS
 

  const linkToVideosLead = (findEl) => `/campaigns/${campaign.id}/videos/${findEl.id}`;
 
  const updateCampaign = (stepTo) => {
     dispatch(
      campaignsOperation.updateCampaignRequest({
        id: campaign.id,
        step: stepTo,
      })
    );
  }

  useEffect(()=>{
    if(entity) {
      const newActiveLead = leads?.find((item) => item.id === +entity);
      setActiveLead(newActiveLead)
    }
    // Set leads with videos
    if (LEADS) {
      const newArr = leads?.map((item, idx) => ({
        index: idx + START_INDEX,
        ...item
      })) 
      const filterArr = newArr.filter((item) => item.video_id);
      setWithVideo(filterArr);
    }
  }, [entity, leads]) 

  useEffect(() => {
    if (currentVideo) {
      dispatch(uploadVideoAndUpdateLead({
        video: currentVideo,
        campaignId: campaign.id,
        lead: activeLead
      }));
    }
  },[currentVideo])

  const onStopMediaRecorder = (blob) => {
    const videoFile = new File([blob], `lead-${activeLead.id}.mp4`, {
      type: getBlobVideoType(blob.type),
    });
    setCurrentVideo(videoFile);
  };

  const {
    status,
    liveStream,
    stopRecording,
    startRecording,
    getMediaStream,
  } = useMediaRecorder({
    recordScreen,
    mediaStreamConstraints: {
      video: {
        aspectRatio: { ideal: VIDEO_RATIO_16_9 },
      },
      audio: true,
    },
    mediaRecorderOptions: {
      mimeType: checkRecordingExceptions() ? VIDEO_WEBM : "",
    },
    onStop: onStopMediaRecorder,
  });

  
  const startRecordingHandler = () => {
    if (recordScreen) getMediaStream();
    else {
      getMediaStream();
      countdown.start().then(() => {
        startRecording();
      });
    }
  };

  useEffect(() => {
    if (recordScreen && status === RECORDER_STATUS.READY) {
      countdown.start().then(() => {
        // For skip first video frames, because it's record counter
        setTimeout(() => startRecording(), RECORDING_DELAY);
      });
    }
  }, [status]);

  const stopRecordingHandler = () => {
    stopRecording();
  };


  const clearCurrentVideo = () => {
    if (activeLead.video_id) {
      dispatch(deleteLeadVideo(campaign.id, activeLead.video_id));
    }
    setCurrentVideo(null);
    updateCampaign(CAMPAIGN_STEPS_VALUE.VIDEOS)
  };

  const changeRecordOption = (isRecordScreen) =>
    setRecordScreen(isRecordScreen);

  const switchVideoType = () => {
    if (campaign) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          video_type: CAMPAIGN_VIDEO_TYPES.generic,
        })
      );
    }
    history.push(linkToVideos);
  };

  const onNextLeadHandler = () => {
    const noVideoEl = leads?.find((item) => !item.video_id);
    if(!noVideoEl) {
      if (campaign?.step < CAMPAIGN_STEPS_VALUE.LANDING_PAGES) {
        updateCampaign(CAMPAIGN_STEPS_VALUE.LANDING_PAGES);
      }
      if(campaign.status !== CAMPAIGN_STATUSES.draft.id){
        updateCampaign(CAMPAIGN_STEPS_VALUE.LANDING_PAGES);
        }
      history.push(linkToLandingPage);
    } else { 
      setActiveLead(noVideoEl);
      history.push(linkToVideosLead(noVideoEl));
      setCurrentVideo(null);
    } 
  };
  return (
    <div className="video">
      <div className="video__container">
        <div className="video__container__controls">
          <h1 className="title">
            Record or upload a personalized videos for each lead
          </h1>
          <CampaignVideosLeadSteps
            leads={leads}
            active={activeLead}
            index={activeLeadIndex}
            className="m-b-30 m-t-25"
          />
          <VideoControls
            videoWasRecorded={videoId}
            status={status}
            onStartRecording={startRecordingHandler}
            onStopRecording={stopRecordingHandler}
            currentVideo={currentVideo}
          />
          <SelectRecordOption
            disabled={status === RECORDER_STATUS.RECORDING}
            onChange={changeRecordOption}
          />
          <FileUpload
            validate
            errorText="The video can be only MP4"
            disabled={status === RECORDER_STATUS.RECORDING}
            title="or"
            withPreview={false}
            withDeleteButton={false}
            accept={["video/mp4"]}
            name="video-upload"
            type="video"
            onChange={(video) => setCurrentVideo(video.target.files[0])}
            btnText="Click to upload video"
            infoText="only MP4 format, max. 200MB"
            classNameBtn="btn-outline-dark"
          />
          <div
            className="switch-video-type"
            role="presentation"
            onClick={toggleModal}
          >
            <Icon color="#393f4d" name={ICONS_NAMES.EXCHANGE} />
            <span>{t("Switch to generic video for all leads")}</span>
          </div>
        </div>
        <div className="video__container__player">
          <h1 className="title m-b-25">
            <Icon
              name={ICONS_NAMES.VIDEO_LIBRARY}
              className="library-icon"
              size={30}
            />
            Videos for new campaign
          </h1>
          {status !== RECORDER_STATUS.RECORDING ? (
            <VideoPlayer
              isShowLoadingProgress
              showWelcomeMessage={false}
              welcomeMessage=""
              onDeleteHandler={clearCurrentVideo}
              mediaBlob={currentVideo}
              showDeleteButton = {campaign.status === CAMPAIGN_STATUSES.draft.id}
              src={videoSrc}
              videoId={videoId}
            />
          ) : (
            <RecordingPreview stream={liveStream} />
          )}
          <span className="video-description m-t-5">
            {videoNumber} 
            {videoDescription}
          </span>
          <LeadsVideoList leads={withVideo} className="m-b-30" />
          {isBtnStatus && <button className="btn btn-blue" onClick={() => onNextLeadHandler()}>{btnStatus}</button>}
          
        </div>
      </div>
      <ModalContainer
        isOpen={isOpenModal}
        closeModal={toggleModal}
        onSubmit={switchVideoType}
        isConfirm
        btnText={{ yes: t("Yes"), no: t("No") }}
      >
        <h3 className="modal_confirmation__body__title">
          {t("Are you sure that you want to change the current option?")}
        </h3>
      </ModalContainer>
    </div>
  );
};

export default VideoEachTypeScreen;
