import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import DomainsListItem from "../domainsListItem";

const DomainsList = ({ domainsList, deleteDomain, validateDomains, activateDomain }) => {
  return (
    <div className="domains-list-container">
      <ul className="domains-list-container__list">
        {domainsList.map((domain) => (
            <DomainsListItem
                key={domain.id}
                validateDomains={validateDomains}
                domain={domain}
                deleteDomain={deleteDomain}
                activateDomain={activateDomain}
            />
        ))}
      </ul>
    </div>
  );
};

DomainsList.propTypes = {
  domainsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deleteDomain: PropTypes.func.isRequired,
    validateDomains: PropTypes.func.isRequired,
    activateDomain: PropTypes.func.isRequired
};

export default DomainsList;
