import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS,
  CAMPAIGN_STEPS_VALUE,
  ICONS_NAMES,
} from "../../constants/common";
import Icon from "../common/icon";
import "./style.scss";
import { CampaignModel } from "../../utils/helpers/defPropTypes";

const LandingPageItem = ({ campaign }) => {
  const previewUrl = `${process.env.REACT_APP_API_URL}/preview-campaign/${campaign?.preview_code}`;
  const publishCampaign = campaign?.status === CAMPAIGN_STATUSES.inactive.id;

  return (
    <li className={`LandingPageItem ${publishCampaign && "inactive"}`}>
      <a
        target="_blank"
        rel="noreferrer"
        className="page-link"
        href={previewUrl}
      >
        {campaign?.landing_page_title}, {campaign?.campaign_title}
      </a>
      <NavLink
        to={{
          pathname: `/campaigns/${campaign.id}/${[
            CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.LANDING_PAGES],
          ]}`,
          state: { isEdit: true },
        }}
        className="LandingPageItem_edit"
      >
        <Icon
          name={ICONS_NAMES.EDIT}
          color="inherit"
          size={20}
          className="m-r-5"
        />
        <span>Edit</span>
      </NavLink>
    </li>
  );
};

LandingPageItem.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
};

export default LandingPageItem;
