import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalContainer from "../../../containers/modal";
import "./style.scss";

const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorRef = useRef(null);
  const error = useSelector((store) => store.ui.error);

  const closeHandler = () => dispatch({ type: "SET_ERROR", payload: null });

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.innerHTML = t(error);
    }
  }, [error]);

  return (
    <ModalContainer isOpen={error} closeModal={closeHandler}>
      <p className="modal_confirmation__body__title" ref={errorRef} />
    </ModalContainer>
  );
};

export default Notifications;
