import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import FileUpload from "../../common/fileUpload"
import InputNew from "../../common/inputNew"
import Loader from "../../common/loader"
import TextArea from "../../common/textArea"
import {
  CAMPAIGN_STEPS,
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
  FILES_TYPES,
  FORM_INITIAL_VALUES,
  LANDING_FORM_PRIORITY,
  UPLOAD_FILES_TYPES,
  CAMPAIGN_STATUSES,
} from "../../../constants/common";
import { deleteFile, uploadFile } from "../../../store/files/operation";
import { useCampaignAndLeads } from "../../../utils/helpers/campaignHooks";
import { campaignsOperation } from "../../../store/campaigns";
import Storage from "../../../utils/helpers/Storage";
import apiUrls from "../../../constants/apiUrls";
import { buttonUrlValidator } from "../../../utils/helpers/stringHelper";
import { rules } from "./rules";
import "./style.scss";

const LandingPagesTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { image: logo } = useSelector((store) => store.files);
  const { campaign } = useCampaignAndLeads();
  const [isValidate, setIsValidate] = useState(false);
  const campaignDraft = campaign && campaign.status === CAMPAIGN_STATUSES.draft.id;

  const onSubmitHandler = useCallback(
    (formData) => {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.PUBLISH,
          ...formData,
          button_url: buttonUrlValidator(formData.button_url),
          logo_file_id: logo ? logo.id : campaign.logo_file_id,
        })
      );
      dispatch(deleteFile(UPLOAD_FILES_TYPES.LOGO));
      history.push(
        `/campaigns/${campaign.id}/${
          CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.PUBLISH]
        }`
      );
    },
    [campaign, logo]
  );

  const formik = useFormik({
    initialValues: {
      image: campaign?.logo_file_id,
      title: campaign?.title || "",
      content: campaign?.content || "",
      button_title: campaign?.button_title || "",
      button_url: campaign?.button_url || "",
    },
    validationSchema: rules,
    enableReinitialize: true,
    onSubmit: onSubmitHandler,
  });

  const getCampaignLogoSrc = useMemo(() => {
    if (campaign && campaign.logo_file_id) {
      return `${process.env.REACT_APP_API_URL}/uploads/campaigns/${campaign.logo_file_id.filename}`;
    }
    return null;
  }, [campaign]);

  const onChangeValue = ({ target: { name, value, files } }) => {
    if (files) {
      const [file] = files;
      dispatch(uploadFile(file, name));
    }
    formik.setFieldValue(
      name,
      files?.length ? files : value,
      isValidate
    );
  };

  const showPreviewUrl = useCallback(() => {
    const token = Storage.get(process.env.REACT_APP_TOKEN_KEY);
    const params = {
      token,
      title: formik.values.title,
      content: formik.values.content,
      button_url: buttonUrlValidator(formik.values.button_url),
      button_title: formik.values.button_title,
      logo_file_id: logo?.id || campaign.logo_file_id?.filename,
      video_file_id: campaign.video?.video_file_id?.filename,
      campaign_id: campaign.id,
    };

    const isWelcomeMessage =
      campaign.video_type === CAMPAIGN_VIDEO_TYPES.generic;

    if (isWelcomeMessage && campaign.welcome_text) {
      params.welcome_text = campaign.welcome_text;
    }

    const esc = encodeURIComponent;
    const qParams = Object.keys(params)
      .map((k) => `${esc(k)}=${esc(params[k])}`)
      .join("&");
    return `${process.env.REACT_APP_API_URL}${apiUrls.preview}?${qParams}`;
  }, [formik.values, logo, campaign]);

  const onDeleteLogo = useCallback(() => {
    onChangeValue({
      target: {
        name: UPLOAD_FILES_TYPES.LOGO,
        value: campaign?.logo_file_id,
      },
    });
    dispatch(deleteFile(UPLOAD_FILES_TYPES.LOGO));
  }, [campaign?.logo_file_id]);

  const logoExist = logo || campaign?.logo_file_id;

  // For scroll to error form input
  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const keys = Object.keys(formik.errors).sort(
        (formKeyA, formKeyB) =>
          LANDING_FORM_PRIORITY[formKeyA] - LANDING_FORM_PRIORITY[formKeyB]
      );
      const [elementSelector] = keys;
      scroller.scrollTo(elementSelector, {
        duration: 1000,
        smooth: "easeOutQuart",
        offset: -100,
      });
    }
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  // For start validate onChange only after first form submit
  useEffect(() => {
    if (formik.isSubmitting) setIsValidate(true);
  }, [formik.isSubmitting]);

  useEffect(() => {
    return logo ? () => dispatch(deleteFile(UPLOAD_FILES_TYPES.LOGO)) : null;
  }, [logo]);

  if (!campaign) {
    return <Loader />;
  }


  return (
    <div>
      <p className="pageSubTitle text-blue m-b-10">
        {campaignDraft ? t("Create a simple personalized landing page") : t("Edit your personalized landing page")}
      </p>
      <div className="landing-page">
        <form onSubmit={formik.handleSubmit}>
          <div className="row form-wrapper m-b-5">
            <div>
              <FileUpload
                showWhenFileAdded={false}
                value={campaign?.logo_file_id}
                logoSrc={getCampaignLogoSrc}
                withDeleteButton
                withPreview
                name={UPLOAD_FILES_TYPES.LOGO}
                className={`${
                  logoExist && "logo-added"
                } row_left upload_logo image`}
                classNameBtn="btn m-b-10 btn-outline-dark"
                onChange={onChangeValue}
                onDelete={onDeleteLogo}
                btnText={t("Select your logo")}
                accept={[FILES_TYPES.IMAGE.JPEG, FILES_TYPES.IMAGE.PNG]}
                infoText={t("only JPG or PNG, 2 MB max.")}
              />
              {formik.errors.image && (
                <span className="text-red">{formik.errors.image}</span>
              )}
            </div>
            <div className="preview-wrapper">
              <a
                rel="noreferrer"
                href={showPreviewUrl()}
                target="_blank"
                className="btn btn-outline preview"
              >
                {t("Preview page")}
              </a>
            </div>
          </div>
          <div className="row m-b-10 form-wrapper p-t-5 m-t-5">
            <div className="border">
              <p className="row_title text-center m-b-5">
                {t("Title section")}
              </p>
              <TextArea
                placeholder={FORM_INITIAL_VALUES.CREATE_PAGE.TITLE}
                maxLength={100}
                id="title"
                error={formik.errors.title}
                defaultValue={t("Insert variable")}
                name="title"
                className="m-b-15 title"
                variables={campaign?.fields}
                onChange={onChangeValue}
                value={formik.initialValues.title}
              />
            </div>
            <div className="text-red">
              <p>{t("Script format")}</p>
              <p>
                {t(
                  "• The variables for personalization need to match the column header of the CSV file of step 1."
                )}
              </p>
              <p>{t("Example script")}</p>
              <p>
                {t("Increase your conversion with personalization,")}
                <span>{"{{Firstname}}"}!</span>
              </p>
            </div>
          </div>

          <div className="row m-b-10 p-t-5 m-t-5 form-wrapper add-video">
            <div className="border border_dashed">
              <p className="row_title text-center m-b-20">
                {t("Video section")}
              </p>
              <p className="pageSubTitle text-blue">
                {t("Your videos will be added according to step 2 „Videos“")}
              </p>
            </div>
          </div>

          <div className="row m-b-10 form-wrapper p-t-5 m-t-5">
            <div className="border">
              <p className="row_title text-center m-b-20">
                {t("Body section")}
              </p>
              <TextArea
                id="content"
                error={formik.errors.content}
                variables={campaign?.fields}
                defaultValue="Insert variable"
                maxLength={500}
                name="content"
                rows="12"
                className="m-b-15 content"
                onChange={onChangeValue}
                value={formik.initialValues.content}
                placeholder={FORM_INITIAL_VALUES.CREATE_PAGE.BODY}
              />
            </div>
            <div className="text-red">
              <p>{t("Script format")}</p>
              <p>
                {t(
                  "• The variables for personalization need to match the column header of the CSV file of step 1."
                )}
              </p>
              <p>{t("Example script")}</p>
              <p>
                You are using sales videos on landing pages at {"{{Company}}"}{" "}
                in {"{{Location}}"}.
              </p>
              <p>
                {t(
                  "The results are great but the conversion rate can’t be high enough, right?"
                )}
              </p>
              <p>
                {t(
                  "Personalize your landing page with already existing data on your leads in a few clicks will help skyrocket your CR!"
                )}
              </p>
              <p>
                We’ve helped clients like {"{{Client1}}"} or {"{{Client2}}"}{" "}
                with our no-bullshit page builder for sales reps who are busy
                selling and not with complicated tools.
              </p>
            </div>
          </div>

          <div className="row m-b-10 form-wrapper p-t-5 m-t-5">
            <div className="border">
              <p className="row_title text-center m-b-20">
                {t("Call to action button")}
              </p>
              <InputNew
                maxLength={100}
                name="button_title"
                onChange={onChangeValue}
                placeholder={t("Add title of your call to action button")}
                value={formik.initialValues.button_title}
                type="input"
                className="button_title"
              />
              {formik.errors.button_title && (
                <span className="text-red">{formik.errors.button_title}</span>
              )}
              <InputNew
                value={formik.initialValues.button_url}
                maxLength={500}
                name="button_url"
                onChange={onChangeValue}
                placeholder={t("Add link")}
                type="input"
                className="button_url"
              />
              {formik.errors.button_url && (
                <span className="text-red">{formik.errors.button_url}</span>
              )}
            </div>
          </div>

          <div className="row row_right">
            <button className="btn btn-blue" type="submit">
              {campaignDraft ? t("Next to publish campaign") : t("Next to publish changes")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LandingPagesTab;
