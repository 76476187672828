import { createActions } from "redux-actions";
import types from "./types";

export const {
  clearCurrentCampaign,
  clearCampaignLeads,
  setCampaignsByStatus,
  setLeadsByCampaign,
  setSearchResults,
  clearCampaignEntities,
  clearRefreshUrlStatus
} = createActions(
  types.CLEAR_CURRENT_CAMPAIGN,
  types.CLEAR_CAMPAIGN_LEADS,
  types.SET_CAMPAIGNS_BY_STATUS,
  types.SET_LEADS_BY_CAMPAIGN,
  types.SET_SEARCH_RESULTS,
  types.CLEAR_CAMPAIGN_ENTITIES,
  types.CLEAR_REFRESH_URL_STATUS
);
