import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { CAMPAIGN_VIDEO_TYPES } from "../../../constants/common";
import "./style.scss";
import { CampaignModel, LeadModel } from "../../../utils/helpers/defPropTypes";
import { campaignsOperation } from "../../../store/campaigns";
import VideoPageListItem from "../videoPageListItem";

const VideoPageItem = ({ campaign, isOpen, campaignLeads }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (campaign.video_type !== CAMPAIGN_VIDEO_TYPES.none) {
      dispatch(campaignsOperation.getCampaignsLeadsRequest(campaign.id));
    }
  }, []);

  const sortedLeads = isOpen ? campaignLeads : campaignLeads.slice(0, 2);

  return (
    <>
      {campaign?.video_type === CAMPAIGN_VIDEO_TYPES.each ? (
        sortedLeads?.map((lead) => (
          <VideoPageListItem
            key={`lead-list-item-${lead.id}`}
            campaign={campaign}
            lead={lead}
          />
        ))
      ) : (
        <VideoPageListItem
          isGenericVideoType
          campaign={campaign}
          lead={campaignLeads && campaignLeads[0]}
        />
      )}
    </>
  );
};

VideoPageItem.defaultProps = {
  campaign: null,
  isOpen: false,
  campaignLeads: null,
};

VideoPageItem.propTypes = {
  campaign: PropTypes.shape(CampaignModel),
  isOpen: PropTypes.bool,
  campaignLeads: PropTypes.arrayOf(PropTypes.shape(LeadModel)),
};

export default VideoPageItem;
