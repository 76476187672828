import React from "react";

const URL = "URL";
const SUBDOMAIN = "subdomain.yourdomain.com/";
const INFO_FIRST = `•  If you have set your custom domain, you can use any link and variables from your CSV list.
                    A unique hash (4 digits) will always follow at the end.`;
const INFO_LANDING_LAST = `•  If you haven’t set a client company variable, the pages will be published
                            under your company and a hash only.` ;
const INFO_MAIN = (title, url) => (<>•  If you don’t set your custom domain, your ${title} will be published under: <br/>
                            <b> we.parruu.com/${url}/from-{"{{your-company}}"}-to-{"{{client-company}}"}-{"{{####}}"}</b></>)                            

export const DOMAIN_ARRAY_INFO = [
    {
        title: "Landing pages",
        main: [
            {
                subTitle: URL,
                description: SUBDOMAIN,
                name: "custom_url",
                maxLength: 100,
            },
            {
                subTitle: "SEO",
                description: "Sitetitle",
                name: "custom_meta_title",
                maxLength: 60,
            },
             {
                subTitle: "",
                description: "Meta description",
                name: "custom_meta_description",
                maxLength: 150,
            }
        ],
        info : [
            INFO_FIRST,
            INFO_MAIN('pages', 'hi'),
            INFO_LANDING_LAST
        ]

    },
    { 
        title: "Videos",
        main:[
            {
                subTitle: URL,
                description: SUBDOMAIN,
                name: "custom_video_url",
                maxLength: 100,
            }
        ],
        info : [
            INFO_FIRST,
            INFO_MAIN('videos', 'hey'),
        ]
    },
    {
        title: "Previews",
        main:[
            {
                subTitle: URL,
                description: SUBDOMAIN,
                name: "custom_preview_url",
                maxLength: 100,
            }
        ],
        info : [
            INFO_FIRST,
            INFO_MAIN('videos', 'preview'),
        ]
    },
   
]