import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Icon from "../icon";
import LoadingProgress from "../loadingProgress";
import { ICONS_NAMES } from "../../../constants/common";
import "./style.scss";

function VideoPlayer({
  mediaBlob,
  onDeleteHandler,
  src,
  onEnded,
  onPlay,
  onPause,
  controls,
  welcomeMessage,
  showWelcomeMessage,
  showDeleteButton,
  isShowLoadingProgress,
  videoId
}) {
  const videoRef = useRef(null);
  const [showMessage, setShowMessage] = useState(true);
  const [playButton, setPlayButton] = useState(true);
  const progressLoading = useSelector(({ files }) => files.loadingProgress);
  const isLoading = useSelector(({ ui: { requestLoading } }) => requestLoading);
  useEffect(() => {
    if (videoRef && !src) {
      if (mediaBlob) {
        videoRef.current.src = URL.createObjectURL(mediaBlob);
        setPlayButton(true);
        setShowMessage(true);
      }
    }
  }, [mediaBlob]);

  const onDeleteVideo = () => {
    videoRef.current.src = null;
    onDeleteHandler();
  }

  const playVideoHandler = () => {
    setShowMessage(false);
    const { current } = videoRef;
    current.play();
    current.controls = true;
    setPlayButton(false);
  };

  const videoExist = src || mediaBlob;
  const isVideoLoading = progressLoading !== null && isLoading;
  const isShowedWelcomeMessage = src && showMessage && showWelcomeMessage

  return (
    <div className="video_wrapper">
      <div
        className={`video_wrapper__container ${
          (videoId && !isVideoLoading) && "active"
        }`}
      >
        {isShowLoadingProgress && isVideoLoading ? (
          <LoadingProgress
            className="video_wrapper__loading"
            done={progressLoading}
          />
        ):
          <>
            <video
                src={src || mediaBlob}
                ref={videoRef}
                onEnded={onEnded}
                onPlay={onPlay}
                onPause={onPause}
                controls={controls}
                className={`video ${!src.length && !mediaBlob && "disabled"}`}
                poster=""
            >
              <track kind="captions"/>
            </video>
          {isShowedWelcomeMessage && (
            <div className="welcome-message-wrapper">
            <span className="welcome-message-wrapper__text">
          {welcomeMessage}
            </span>
            </div>
            )}
          {playButton && (
            <button className="play_button" onClick={playVideoHandler}>
            <Icon name={ICONS_NAMES.PLAY} size={50} color="#FFFFFF" />
            </button>
            )}
          {showDeleteButton && videoExist && (
            <button className="delete_button" onClick={onDeleteVideo}>
            <Icon size={40} name={ICONS_NAMES.CROSS_CIRCLE} color="#393f4d" />
            </button>
            )}
          </>
          }
      </div>
    </div>
  );
}

VideoPlayer.defaultProps = {
  mediaBlob: null,
  onDeleteHandler: null,
  onEnded: null,
  onPlay: null,
  onPause: null,
  src: null,
  controls: true,
  welcomeMessage: "Optional welcome message here",
  showWelcomeMessage: false,
  showDeleteButton: false,
  isShowLoadingProgress: false,
  videoId: null
};
VideoPlayer.propTypes = {
  showDeleteButton: PropTypes.bool,
  mediaBlob: PropTypes.shape(),
  onDeleteHandler: PropTypes.func,
  onEnded: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  src: PropTypes.string,
  controls: PropTypes.bool,
  welcomeMessage: PropTypes.string,
  showWelcomeMessage: PropTypes.bool,
  isShowLoadingProgress: PropTypes.bool,
  videoId: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
};

export default VideoPlayer;
